import localStorage from "library/utilities/localStorage"
import i18n from "i18next"

export const supportedLanguages = [
  "en",
  "de",
  "fr",
  "es",
  "pl",
  "it",
  "nl",
  "pt",
]

export const setLanguage = (
  language: string | null,
  setLanguage: (lang: string) => void
) => {
  // Set the language only if language is available and it's supported
  if (!!language && supportedLanguages.includes(language)) {
    localStorage.setItem("locale", language)
    void i18n.changeLanguage(language)
    setLanguage(language)
  }
}
