import { takeLatest, put, select, all } from "redux-saga/effects"

import * as serverDataActions from "library/common/actions/serverData"
import * as teethActions from "library/common/actions/teeth"
import * as serverDataSelectors from "library/common/selectors/serverData"
import * as entitiesSelectors from "library/common/selectors/entities"
import teethTypes from "library/common/types/teethTypes"
import { Tooth } from "../types/teethTypes"
import { Detection, UserChange } from "../types/dataStructureTypes"
import { AnnotationName, AnnotationOnTooth } from "../types/adjustmentTypes"
import { setDrawingCanvases, setShowDrawingWarning } from "../actions/drawing"
import { DrawingAction } from "../types/drawing"
import {
  drawingActionHasMatchingAnnotation,
  getDrawingAction,
  isDrawing,
} from "../selectors/drawing"

function* createToothSaga({
  payload: { id, removeRejectedAnnotations },
}: ReturnType<typeof teethActions.createTooth>) {
  try {
    const activeTooth = id
    if (!activeTooth) {
      return
    }

    const allRemovedTeeth: Tooth[] = yield select(
      serverDataSelectors.getAllRemovedTeeth
    )
    const foundInRemovedTeeth: boolean = allRemovedTeeth.some(
      (tooth) => tooth.toothName === activeTooth
    )
    const drawingAction: DrawingAction = yield select(getDrawingAction)
    const hasMatchingAnnotation: boolean = yield select(
      drawingActionHasMatchingAnnotation
    )
    const drawing: boolean = yield select(isDrawing)

    yield put(
      setDrawingCanvases([
        {
          activeId: `${activeTooth}-${drawingAction}`,
          hidden: drawing ? false : !hasMatchingAnnotation,
        },
      ])
    )

    if (foundInRemovedTeeth) {
      // Delete from removed teeth.
      yield put(serverDataActions.userDeleteDeletedTeeth(activeTooth))
      if (removeRejectedAnnotations) {
        // Also delete all rejected annotations
        const allDetections: Detection[] = yield select(
          entitiesSelectors.getDetections
        )
        const movedChange: UserChange[] = yield select(
          serverDataSelectors.getAllUserChanges
        )
        const movedDetectionIds = movedChange
          .filter((change) => change.action === "moved")
          .map((change) => change.annotationId)

        const annotationsForThatTooth = allDetections.filter(
          (detection) =>
            detection.toothName === activeTooth &&
            !movedDetectionIds.includes(detection.id)
        )
        yield all(
          annotationsForThatTooth.map((annotation) =>
            put(
              serverDataActions.deleteUserChange({
                id: annotation.id,
                deleteNonHSM: true,
              })
            )
          )
        )
      }
    } else {
      // Add to added teeth.
      const newTooth: Tooth = { toothName: activeTooth }
      yield put(serverDataActions.userAddAddedTeeth([newTooth]))
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteToothSaga({
  payload: { id, rejectAnnotations },
}: ReturnType<typeof teethActions.deleteTooth>) {
  try {
    const activeTooth = id
    if (!activeTooth) {
      return
    }
    const allAddedTeeth: Tooth[] = yield select(
      serverDataSelectors.getAllAddedTeeth
    )
    const foundInAddedTeeth: boolean = allAddedTeeth.some(
      (tooth) => tooth.toothName === activeTooth
    )
    const allAdditions: AnnotationOnTooth[] = yield select(
      serverDataSelectors.getAllAdditions
    )
    const additionsForTooth = allAdditions.filter(
      (addition) => addition.toothName === activeTooth
    )
    // Delete all additions for specific tooth
    yield all(
      additionsForTooth.map((addition) =>
        put(serverDataActions.deleteUserAddition(addition))
      )
    )

    for (const addition of additionsForTooth) {
      yield put(
        setDrawingCanvases([
          {
            activeId: `${activeTooth}-${
              addition.type === AnnotationName.restorations
                ? addition.subtype
                : addition.type
            }`,
            hidden: true,
          },
        ])
      )
    }
    // TODO: when you delete a tooth that has moved annotations they are not deleted
    // example: tooth 27 has an AI detected caries and is moved to 28. When you delete 28 the caries is not rejected and stays visible on the image
    if (foundInAddedTeeth) {
      // Delete from added teeth.
      yield put(serverDataActions.userDeleteAddedTeeth(activeTooth))
    } else {
      // Add to removed teeth.
      const newTooth: Tooth = { toothName: activeTooth }
      yield put(serverDataActions.userAddDeletedTeeth([newTooth]))
      if (rejectAnnotations) {
        // Reject all detections for that tooth.
        const allDetections: Detection[] = yield select(
          entitiesSelectors.getDetections
        )
        const movedChange: UserChange[] = yield select(
          serverDataSelectors.getAllUserChanges
        )
        const movedDetectionIds = movedChange
          .filter((change) => change.action === "moved")
          .map((change) => change.annotationId)
        const annotationsForThatTooth = allDetections.filter(
          (detection) =>
            detection.toothName === activeTooth &&
            !movedDetectionIds.includes(detection.id)
        )

        yield put(
          serverDataActions.addUserChanges(
            annotationsForThatTooth.map((annotation) => {
              const newChange: UserChange = {
                annotationId: annotation.id,
                action: "rejected",
              }

              return newChange
            })
          )
        )
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* hideDrawingWarning() {
  yield put(setShowDrawingWarning(false))
}

export default function* entitiesSaga() {
  yield takeLatest(teethTypes.CREATE_TOOTH, createToothSaga)
  yield takeLatest(teethTypes.DELETE_TOOTH, deleteToothSaga)
  yield takeLatest(teethTypes.SET_ACTIVE_TOOTH, hideDrawingWarning)
}
