import { Boneloss, Detection, ToothSegment } from "./dataStructureTypes"
import { Tooth } from "./teethTypes"

export enum entitiesTypes {
  SAVE_DETECTIONS = "@@ENTITIES/SAVE_DETECTIONS",
  SET_INITIAL_STATE = "@@ENTITIES/SET_INITIAL_STATE",
  SET_IMAGE_ID = "@@ENTITIES/SET_IMAGE_ID",
  ADD_DETECTED_TEETH = "@@ENTITIES/ADD_DETECTED_TEETH",
  DELETE_DETECTED_TOOTH = "@@ENTITIES/DELETE_DETECTED_TOOTH",
  SET_TOOTH_BONELOSS = "@@SERVER_DATA/SET_TOOTH_BONELOSS",
}

export type Entities = Readonly<{
  apical: Detection[]
  boneloss?: Boneloss
  caries: Detection[]
  restorations: Detection[]
  detectedTeeth: Tooth[]
  imageId: string
  calculus: Detection[]
  nervus: Detection[]
  segments: ToothSegment[]
}>
