import { ActionLogin, CoachMarkRecord } from "library/common/actions/user"
import { fetch } from "../utilities/authFetch"
import authorizedFetch from "../utilities/fetch"

export const requestLogin = (data: ActionLogin) => {
  if (data.isSSO) {
    return fetch.post("/sso-login/", {
      sso_provider: data.email,
      token: data.password,
    })
  } else {
    return fetch.post("/login/", {
      username: data.email,
      password: data.password,
    })
  }
}
export const requestGetUserName = () => authorizedFetch.get(`/user/me/`)
export const requestSetKnownHandlers = (data: { knownHandlers: string[] }) =>
  authorizedFetch.post(`/user/me/`, data)
export const requestSetWhatsNew = (data: { whatsNew: string }) =>
  authorizedFetch.post(`/user/me/`, data)

export const requestSetLastCoachMarks = (data: {
  lastCoachMarks: CoachMarkRecord
}) => authorizedFetch.post(`/user/me/`, data)
