import axios from "axios"
import { store } from "core/store/configureStore"
import {
  logOutAction,
  setServerError,
  setServerErrorMessage,
} from "library/common/actions/user"
import { setShouldTakeScreenshot } from "library/common/actions/upload"

export const baseURL = process.env.REACT_APP_API_URL

const fetch = axios.create({ baseURL })

const hasExpectedData = (data: any) =>
  data &&
  (data.imageWidth ||
    data.imageHeight ||
    data.image ||
    data.newPassword ||
    data.oldPassword)

export const errorHandler = (error: any) => {
  if (error.response && error.response.status === 403) {
    store.dispatch(logOutAction())
  } else if (
    error.response &&
    (error.response.status === 502 || error.response.status === 503)
  ) {
    store.dispatch(setServerError("unavailable"))
  } else if (!navigator.onLine || !error.response) {
    store.dispatch(setServerError("disconnect"))
  } else if (
    error.response &&
    (!hasExpectedData(error.response.data) || error.response.status === 400)
  ) {
    // show an error popup when the response doesn't contain a type of error we can handle
    store.dispatch(setServerError(`${error.response.status}`))
    store.dispatch(setShouldTakeScreenshot(true))
    store.dispatch(setServerErrorMessage(error.response.data?.error))
  }

  return Promise.reject({ ...error })
}
const successHandler = (response: any) => response

fetch.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default fetch
