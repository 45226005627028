import {
  Detection,
  DetectionInfo,
  UserChange,
} from "library/common/types/dataStructureTypes"
import { isHighSensitivityMode } from "library/common/selectors/utils/filterSensAnnotations"
import { getAnnotationNameType } from "library/common/types/utils/annotations"

type HsmDataType = Readonly<{
  all: number[]
  accepted: number[]
  rejected: number[]
  unconfirmed: number[]
  enlargements: number[]
  hsmIdsToDisplay: number[]
  hiddenIds: number[]
}>

export const parseHsmData = (
  detections: Detection[],
  changes: UserChange[],
  kind: string,
  visibleEntities: DetectionInfo[],
  cariesPro?: boolean,
  isHighSenseModeActive?: boolean
): HsmDataType => {
  const filterHSMChanges = (action: string) =>
    changes
      .filter((change) => change.action === action && change.isHSM)
      .map((change) => change.annotationId)
  const accepted = filterHSMChanges("accepted")
  const rejected = filterHSMChanges("rejected")

  const visibleIds = visibleEntities
    .filter((v) => !!v.visible)
    .map((detection) => detection.detection.id)

  const all: number[] = detections
    .filter(
      (detection: Detection) =>
        isHighSensitivityMode(detection.subtype) &&
        visibleIds.includes(detection.id) // Only include detections that are not deleted
    )
    .map((detection: Detection) => detection.id)
  const unconfirmed = all.filter(
    (id) => !accepted.includes(id) && !rejected.includes(id)
  )

  const filterToothAnnotations = (toothName: number, subtype: string) =>
    detections.filter(
      (a) =>
        // returns list of annotations from the same subtype at tooth level excluding hsm annotations.
        a.toothName === toothName &&
        getAnnotationNameType(a.subtype) === getAnnotationNameType(subtype) &&
        !isHighSensitivityMode(a.subtype)
    )

  const enlargements: number[] = []
  // go over all unconfirmed hsm and check whether or not they are enlargements
  unconfirmed.forEach((id) => {
    const unconfirmedRef = detections.find((a) => a.id === id)
    if (!unconfirmedRef) return

    // filter annotations based on the subtype and toothName of the unconfirmed id
    const toothAnnotations = filterToothAnnotations(
      unconfirmedRef.toothName,
      unconfirmedRef.subtype
    )

    const hasAnnotation =
      kind === "PERI" // on PERI enlargements are marked explicitly
        ? !!unconfirmedRef.replacing
        : cariesPro
        ? toothAnnotations.some(
            (a) =>
              a.location === unconfirmedRef.location &&
              a.depth === unconfirmedRef.depth
          )
        : !!toothAnnotations.length

    // if an annotation is found in the same tooth then that unconfirmed ID is consider an enlargement
    if (hasAnnotation) return enlargements.push(id)
  })

  // HSM annotations that are not accepted, rejected or on a deleted tooth
  const displayables: number[] = unconfirmed.filter(
    (id) => !enlargements.includes(id)
  )

  const fusionDetections = visibleEntities
    .filter((v) => v.detection.fusion)
    .map((fusion) => fusion.detection)

  // If both affected id's of the fusion is in displayables, show fusion id
  const fusedIdsToShow = fusionDetections.flatMap(
    (f) => (f.fusion?.every((id) => displayables.includes(id)) && f.id) || []
  )

  // Rest of the fusion id's that are not shown
  const fusionIdsToHide = fusionDetections
    .filter((f) => !fusedIdsToShow.includes(f.id))
    .map((fusion) => fusion.id)

  // Individual detection id's that are part of a fusion
  const fusionAffectedIds = fusionDetections.flatMap(
    (f) => (fusedIdsToShow.includes(f.id) && f.fusion) || []
  )

  /*
    Whenever all individual id's are accepted of a fusion, return that fusion id
    so that we can use it in hiddenIds to NOT hide the fusion blob. This is separate
    from the fusedIdsToShow as this shows the bounding box.
  */
  const acceptedFusionIdsToShow = fusionDetections.flatMap(
    (f) => (f.fusion?.every((id) => accepted.includes(id)) && f.id) || []
  )

  const hiddenIds = fusionIdsToHide
    .filter((f) => !acceptedFusionIdsToShow.includes(f))
    .concat(fusionAffectedIds)

  const hsmIdsToDisplay = displayables
    .filter(
      (h) =>
        isHighSenseModeActive &&
        !fusionAffectedIds.includes(h) &&
        !fusionIdsToHide.includes(h)
    )
    .concat(fusedIdsToShow)

  return {
    all,
    accepted,
    rejected,
    unconfirmed,
    enlargements,
    hsmIdsToDisplay,
    hiddenIds,
  }
}
