import {
  takeLatest,
  put,
  select,
  call,
  all,
  takeEvery,
} from "redux-saga/effects"

import * as adjustmentActions from "library/common/actions/adjustments"
import * as teethActions from "library/common/actions/teeth"
import * as serverDataActions from "library/common/actions/serverData"
import * as withHistoryActions from "library/common/actions/withHistory"

import * as adjSelectors from "library/common/selectors/adjustments"
import * as entitiesSelectors from "library/common/selectors/entities"
import * as serverDataSelectors from "library/common/selectors/serverData"
import * as teethSelectors from "library/common/selectors/teeth"
import { isHighSensitivityMode } from "library/common/selectors/utils/filterSensAnnotations"
import { getMovingIndexPosition } from "library/common/selectors/utils/displayHorizontal"
import {
  isUpper,
  offsetFromLeft,
} from "library/common/selectors/utils/toothNumbers"

import * as annotationsUtils from "library/common/types/utils/annotations"
import {
  adjustmentTypes,
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
  ShiftingBounds,
} from "library/common/types/adjustmentTypes"
import { Detection, UserChange } from "library/common/types/dataStructureTypes"
import {
  AnnotationRecord,
  AnnotationToRender,
  BoneLossFormUser,
  ServerDataTypes,
} from "library/common/types/serverDataTypes"
import { Tooth } from "library/common/types/teethTypes"
import { setDataIsChanged } from "../actions/saving"
import { setDrawingAction, setDrawingCanvases } from "../actions/drawing"
import { DrawingAction } from "../types/drawing"
import { isDrawing } from "../selectors/drawing"

const onlyUnique = (value: any, index: any, self: any) =>
  self.indexOf(value) === index
const uniqueBy = (arr: any, field: string) =>
  Object.values(
    arr.reduce((acc: any, cur: any) => ({ ...acc, [cur[field]]: cur }), {})
  )

const updateToothAnnots = (aTooth: any, nTooth: any) => {
  const aToothAnnots = aTooth?.annotations || {}
  const nToothAnnots = nTooth?.annotations || {}
  const anKeys = [
    ...Object.keys(aToothAnnots),
    ...Object.keys(nToothAnnots),
  ].filter(onlyUnique)

  return anKeys.reduce((acc: any, annField: string) => {
    const nextAToothAnnots = aToothAnnots[annField]
      ? aToothAnnots[annField]
      : []
    const nextNToothAnnots = nToothAnnots[annField]
      ? nToothAnnots[annField]
      : []
    const anToothAnnots = [...nextAToothAnnots, ...nextNToothAnnots]
    const uniqueAnnots = uniqueBy(anToothAnnots, "id")
    const existingAnToothAnnots = uniqueAnnots.filter(
      (an: any) => an.status !== "remove"
    )
    if (existingAnToothAnnots.length) acc[annField] = existingAnToothAnnots

    return acc
  }, {})
}

const cleanStack = (stack: any, mStack: any, label: string) => {
  const mToothId = Object.keys(mStack)[0]

  return Object.keys(stack).reduce((acc: any, toothId: string) => {
    const annots = stack[toothId].annotations
    if (mToothId === toothId) {
      const filteredAnnots = Object.keys(annots)
        .filter((an: any) => an !== label)
        .reduce(
          // eslint-disable-next-line
          (nAcc: any, label: string) => ((nAcc[label] = annots[label]), nAcc),
          {}
        )
      if (!Object.keys(filteredAnnots).length) return acc
      acc[toothId] = { annotations: filteredAnnots }
    }

    // eslint-disable-next-line
    return (acc[toothId] = stack[toothId]), acc
  }, {})
}

const restorations = ["bridges", "crowns", "fillings", "implant", "roots"]

function* createNextStack(nextTeethStack: any) {
  const adjustedTeethStack: {} = yield select(adjSelectors.getAdjustments)
  const aStackKeys = Object.keys(adjustedTeethStack)
  const nStackKeys = Object.keys(nextTeethStack)
  const cStackKeys = [...aStackKeys, ...nStackKeys].filter(onlyUnique)

  const nextStack = cStackKeys.reduce((acc: any, cToothId: any) => {
    const isInNextStack = nStackKeys.some((aToothId) => aToothId === cToothId)
    const isInAdjStack = aStackKeys.some((aToothId) => aToothId === cToothId)
    const noAnnots = !nextTeethStack[cToothId]?.annotations
    if (!isInNextStack) {
      // eslint-disable-next-line
      return (acc[cToothId] = adjustedTeethStack[cToothId]), acc
    }
    if (!isInAdjStack || noAnnots) {
      // eslint-disable-next-line
      return (acc[cToothId] = nextTeethStack[cToothId]), acc
    }
    const updatedToothAnnots = updateToothAnnots(
      adjustedTeethStack[cToothId],
      nextTeethStack[cToothId]
    )
    if (!Object.keys(updatedToothAnnots).length) return acc

    // eslint-disable-next-line
    return (acc[cToothId] = { annotations: updatedToothAnnots }), acc
  }, {})

  return nextStack
}

function* adjustTeethAnnotsSaga({
  payload: nextTeethStack,
}: ReturnType<typeof adjustmentActions.adjustAnnotations>) {
  const movingStack: {} = yield select(adjSelectors.getMovingStack)
  const movingAnnotLabel: string = yield select(
    adjSelectors.getMovingAnnotation
  )
  const isInRestorations = restorations.some(
    (label: string) => label === movingAnnotLabel
  )
  const nextMovingAnnotLabel = isInRestorations
    ? "restorations"
    : movingAnnotLabel
  const nextStack: {} = yield call(createNextStack, nextTeethStack)
  const filterNextStack = movingAnnotLabel
    ? cleanStack(nextStack, movingStack, nextMovingAnnotLabel)
    : nextStack
  yield put(adjustmentActions.adjustAnnotationsSuccess(filterNextStack))
}

function* moveAnnotationStartSaga({
  payload: movingLabel,
}: ReturnType<typeof adjustmentActions.moveAnnotation>) {
  const movingAnnotLabel: string = yield select(
    adjSelectors.getMovingAnnotation
  )
  const activeToothId: number = yield select(serverDataSelectors.getActiveTooth)
  const activeToothAnnots: { annotations: AnnotationRecord } = yield select(
    serverDataSelectors.filteredAnnotationsDataForTooth
  )

  const isInRestorations = restorations.includes(movingLabel)
  const nextMovingLabel = isInRestorations ? "restorations" : movingLabel

  const annotation = activeToothAnnots.annotations[nextMovingLabel]
  const properAnnot = isInRestorations
    ? annotation?.filter((an: any) => an.subtype === movingLabel)
    : annotation
  const nextAnnot = {
    [activeToothId]: { annotations: { [nextMovingLabel]: properAnnot } },
  }
  const nextRemoveAnnot = {
    [activeToothId]: { annotations: { [nextMovingLabel]: [] } },
  }

  const isMoving = movingLabel === movingAnnotLabel
  if (isMoving) {
    yield put(adjustmentActions.moveAnnotationSuccess(""))
    yield put(adjustmentActions.moveAnnotationToSuccess({}))
    yield put(adjustmentActions.adjustAnnotations(nextAnnot))
  } else {
    yield put(adjustmentActions.moveAnnotationSuccess(movingLabel))
    yield put(adjustmentActions.moveAnnotationToSuccess(nextAnnot))
    yield put(adjustmentActions.adjustAnnotations(nextRemoveAnnot))
  }
}

function* moveAnnotationToSaga({
  payload: dirIndex,
}: ReturnType<typeof adjustmentActions.moveAnnotationTo>) {
  yield put(withHistoryActions.rememberState())

  const nextToothMoveTo: (id: number) => number = yield select(
    serverDataSelectors.getNextToothMoveTo
  )
  const nextTooth = nextToothMoveTo(dirIndex)
  if (!nextTooth) return false

  const existingTeeth: Tooth[] = yield select(
    serverDataSelectors.getTeethToRender
  )
  const toothExists = existingTeeth.some(
    (tooth) => tooth.toothName === nextTooth
  )
  if (!toothExists) {
    yield put(
      teethActions.createTooth({
        id: nextTooth,
        removeRejectedAnnotations: false, // Tooth which is created by moving an annotation to it, will NOT reactivate its detections.
      })
    )
  }

  const activeToothAnnotations: {
    tooth: number
    annotations: AnnotationRecord
  } = yield select(serverDataSelectors.filteredAnnotationsDataForTooth)

  const movingLabel: string = yield select(adjSelectors.getMovingAnnotation)

  const annotationsToMove: AnnotationToRender[] = restorations.includes(
    movingLabel
  )
    ? activeToothAnnotations.annotations.restorations?.filter(
        ({ subtype }) => subtype === movingLabel
      )
    : activeToothAnnotations.annotations[movingLabel]

  const detections = annotationsToMove.filter(({ isAddition }) => !isAddition)

  // move all AI detections
  yield all(
    detections.map((detection) =>
      put(
        serverDataActions.addUserChanges([
          {
            action: "moved",
            annotationId: detection.id ?? 0,
            newTooth: nextTooth,
          },
        ])
      )
    )
  )

  const additions = annotationsToMove.filter(({ isAddition }) => isAddition)

  // move user additions.
  // NOTE: this might create duplicate additions for some teeth but it doesn't hurt anyone
  yield all(
    additions.map(({ type, subtype, location, depth, id }) =>
      put(
        serverDataActions.changeUserAddition({
          toothName: nextTooth,
          type,
          subtype,
          location,
          depth,
          id,
        })
      )
    )
  )

  yield put(teethActions.setActiveTooth(nextTooth))
  yield call(disableAnnotationCombinationsBySubtype, movingLabel)
}

function* disableAnnotations(
  annotation: AnnotationOnTooth,
  changes: UserChange[],
  detections: Detection[]
) {
  // Checkbox is currently active.
  // Checkbox is active when:
  // 1. HSM was accepted (for Caries and Apical)
  // 2. additions contains this tooth with the type
  // 3. annotation was detected by the backend
  // Strategy: delete all found hsmDetections and deleteUserAddition
  // and if annotation was detected, add rejections for all found detections
  const detectionsWithoutHsm = detections.filter(
    (detection) => !isHighSensitivityMode(detection.subtype)
  )

  // Reject all user added HSM changes
  yield all(
    changes
      .filter((c) => c.isHSM)
      .map((change) =>
        put(
          serverDataActions.deleteUserChange({
            id: change.annotationId,
            deleteNonHSM: true,
          })
        )
      )
  )

  const hsmIds = detections
    .filter((detection) => isHighSensitivityMode(detection.subtype))
    .map((d) => d.id)

  yield all(
    changes
      .filter(
        (change) =>
          hsmIds.includes(change.annotationId) &&
          change.isHSM &&
          change.action !== "rejected"
      )
      .map((change) =>
        put(
          serverDataActions.deleteUserChange({
            id: change.annotationId,
            deleteNonHSM: true,
          })
        )
      )
  )

  yield put(serverDataActions.deleteUserAddition(annotation))

  // Reject normal detection as well as hsm detection
  if (detectionsWithoutHsm.length > 0) {
    yield put(
      serverDataActions.addUserChanges(
        detections.map((detection) => ({
          action: "rejected",
          annotationId: detection.id,
        }))
      )
    )
  }
}

function* enableAnnotations(
  annotation: AnnotationOnTooth,
  changes: UserChange[],
  detections: Detection[]
) {
  const { toothName } = annotation
  const detectionsWithoutHsm = detections.filter(
    (detection) => !isHighSensitivityMode(detection.subtype)
  )

  // If tooth does not exist, create it without reading removed detections.
  const activeToothExist: boolean | null = yield select(
    serverDataSelectors.getActiveToothExists
  )
  if (!activeToothExist) {
    yield put(
      teethActions.createTooth({
        id: toothName,
        removeRejectedAnnotations: false,
      })
    )
  }

  // Reject HSM detections when we add bridges or fillings
  if (
    annotation.subtype === RestorationSubtype.bridges ||
    annotation.subtype === RestorationSubtype.fillings
  ) {
    const toothDetectionsBySubtype: Record<string, Detection[]> = yield select(
      serverDataSelectors.getDetectionsForActiveToothBySubtype
    )
    const hsmTypes = [AnnotationName.apical, AnnotationName.caries]
    const hsmDetections = hsmTypes.flatMap((h) =>
      toothDetectionsBySubtype[h].filter((t) =>
        isHighSensitivityMode(t.subtype)
      )
    )
    const hsmDetectionsIds = new Set(hsmDetections.map((d) => d.id))
    const changesHasHsmDetectionId = changes.some((c) =>
      hsmDetectionsIds.has(c.annotationId)
    )

    // Only add rejection once
    if (!changesHasHsmDetectionId) {
      yield all(
        hsmDetections.map((detection) =>
          put(
            serverDataActions.addUserChanges([
              {
                action: "rejected",
                annotationId: detection.id,
                isHSM: true,
              },
            ])
          )
        )
      )
    }
  }

  const allAdditions: AnnotationOnTooth[] = yield select(
    serverDataSelectors.getAllAdditions
  )

  const annotationAdditions = allAdditions.filter(
    (a) => a.toothName === annotation.toothName && a.type === annotation.type
  )
  const drawing: boolean = yield select(isDrawing)
  /*
  When we are toggling annotations by drawing on a tooth with an existing detection of the same
  type, we still need to add the user addition, so that when we delete the tooth, the detection as
  well as the drawing addition is deleted.
  */
  if (drawing && detections.length > 1 && annotationAdditions.length === 0) {
    yield put(serverDataActions.addUserAdditions([annotation]))
  }

  const annotationAdditionsTypes = annotationAdditions.map(
    (a) => a.subtype || a.type
  )

  // Checkbox is currently inactive.
  // Checkbox is inactive when:
  // 1. A UserChange with action="rejected" was added
  // 2. Nothing was detected by the backend.
  if (detectionsWithoutHsm.length > 0) {
    // In case 1 there must exist a detection by the backend. Delete all UserChanges.
    const detectionIds = new Set(detections.map((d) => d.id))
    yield all(
      changes
        .filter((change) => detectionIds.has(change.annotationId))
        .map((change) =>
          put(
            serverDataActions.deleteUserChange({
              id: change.annotationId,
              deleteNonHSM: true,
            })
          )
        )
    )
  } else {
    /*
    When drawing calculus, add both mesial and distal detections.
    When an annotation does not exist on the tooth yet, add it else add nothing.
    */
    const additions =
      annotation.type === "calculus" && !annotation.location
        ? [
            {
              ...annotation,
              location: "m",
            },
            {
              ...annotation,
              location: "d",
            },
          ]
        : /*
            When type is calculus, we can only have two elements in annotationAdditions. When there is one element
            and the location is not the same as annotation.location, we add an addition, else we add no addition.
            When drawing, don't add a new addition if it already exists in allAdditions
          */
        annotationAdditions.length < 1 ||
          !annotationAdditionsTypes.includes(
            annotation.subtype || annotation.type
          ) ||
          (annotation.type === "calculus" &&
            annotation.location !== annotationAdditions[0]?.location)
        ? [annotation]
        : []
    yield put(serverDataActions.addUserAdditions(additions))
  }
}

//TODO (carlos): annotation types and subtypes shouldn't be hardcoded
/** Get the annotation type passing the annotation subtype in the arguments if the annotation subtype found. */
const getType = (subtype: string): AnnotationName => {
  const restorations = ["bridges", "crowns", "fillings", "implants", "roots"]
  // Returning as default the subtype pass when is not a restoration
  //  handles the `caries` & `apical` cases since the `subtype` pass in
  //  args is actually their `type`.
  return restorations.includes(subtype)
    ? AnnotationName.restorations
    : (subtype as AnnotationName)
}

/** Get the annotation subtype passing a general subtype name. */
const getSubtype = (subtype: string): RestorationSubtype | undefined => {
  // Caries & apical annotations only have a type, but not a subtype.
  //  And returning the subtype pass in args handles the restorations
  //  cases since we dont have special cases for them
  return ["caries", "apical", "calculus", "nervus"].includes(subtype)
    ? undefined
    : (subtype as RestorationSubtype)
}

/** Get the annotation subtype list that shouldn't be enabled when subtype pass in args is enabled. */
const BASE_DISABLING = [
  "caries",
  "apical",
  "calculus",
  "fillings",
  "roots",
  "nervus",
]
const DISABLING_CASES = {
  crowns: ["bridges"],
  caries: ["implants", "bridges"],
  apical: ["implants", "bridges"],
  nervus: ["implants", "bridges"],
  calculus: ["implants", "bridges"],
  fillings: ["implants", "bridges"],
  roots: ["implants", "bridges"],
  implants: BASE_DISABLING.concat("bridges"),
  bridges: BASE_DISABLING.concat("crowns", "implants"),
}

/** Pass an annotation subtype and disable all other subtypes not allowed to be enabled on the current active tooth */
function* disableAnnotationCombinationsBySubtype(subtype: string) {
  const disablingCases: string[] = DISABLING_CASES[subtype] || []
  if (disablingCases.length === 0) return

  const toothDetectionsBySubtype: Record<string, Detection[]> = yield select(
    serverDataSelectors.getDetectionsForActiveToothBySubtype
  )
  const changes: UserChange[] = yield select(
    serverDataSelectors.getAllUserChanges
  )
  const toothName: number = yield select(serverDataSelectors.getActiveTooth)

  for (const sub of disablingCases) {
    const annotation: AnnotationOnTooth = {
      toothName,
      subtype: getSubtype(sub),
      type: getType(sub),
    }
    yield call(
      disableAnnotations,
      annotation,
      changes,
      toothDetectionsBySubtype[sub]
    )
    // Hide drawing canvases
    yield put(
      setDrawingCanvases([{ activeId: `${toothName}-${sub}`, hidden: true }])
    )
  }
}

// TODO (carlos): decouple function from return type (if possible) actions `serverDataActions.addCariesAdditions`
//  to allow it to be reusable. and same for `disableAnnotationCombinationsByAnnotationId`
function* disableAnnotationCombinationsForCariesAdditions({
  payload,
}: ReturnType<typeof serverDataActions.addCariesAdditions>) {
  const { type, subtype }: AnnotationOnTooth = payload[0]
  const diagnose = subtype || type
  yield call(disableAnnotationCombinationsBySubtype, diagnose)
}

function* disableAnnotationCombinationsByAnnotationId({
  payload,
}: ReturnType<typeof serverDataActions.toggleAnnotation>) {
  const annotationId: number = payload
  const annotations: Detection[] = yield select(
    serverDataSelectors.getDetectionsToRenderForAllTeeth
  )
  const annotation: Detection = annotations.filter(
    (detection) => detection.id === annotationId
  )[0]
  const diagnose = annotationsUtils.getAnnotationNameType(annotation.subtype)
  yield call(disableAnnotationCombinationsBySubtype, diagnose)
}

function* toggleAnnotationOnToothSaga({
  payload,
}: ReturnType<typeof adjustmentActions.toggleAnnotationOnTooth>) {
  yield put(withHistoryActions.rememberState())
  const enablingAnnotations = !payload.isChecked

  const { subtype, type, location }: AnnotationOnTooth = payload.annotation
  const diagnose = subtype || type

  const toothDetections: Record<string, Detection[]> = yield select(
    serverDataSelectors.getDetectionsForActiveToothBySubtype
  )
  const diagnosisDetections = toothDetections[diagnose]
  const relevantDetections = location
    ? diagnosisDetections.filter((a) => a.location == location)
    : diagnosisDetections

  const changes: UserChange[] = yield select(
    serverDataSelectors.getAllUserChanges
  )

  const toothName: number = yield select(serverDataSelectors.getActiveTooth)

  // We don't have drawing buttons for these entries
  const excludedDrawingDetections = ["nervus", "implants", "roots"]

  // Set drawing annotation as clicked detection
  const drawing: boolean = yield select(isDrawing)

  const allAdditions: AnnotationOnTooth[] = yield select(
    serverDataSelectors.getAllAdditions
  )

  const filteredCalculusAdditions = allAdditions.filter(
    (a) => a.type === AnnotationName.calculus
  )

  const activeToothAnnotations: { annotations: AnnotationRecord } =
    yield select(serverDataSelectors.filteredAnnotationsDataForTooth)

  const activeDiagnosesOnTooth = activeToothAnnotations.annotations[diagnose]

  const activeToothLocations = (location: string | undefined) =>
    activeDiagnosesOnTooth
      .map((a: AnnotationOnTooth) => a.location)
      .includes(location)

  if (
    !excludedDrawingDetections.includes(diagnose) &&
    !drawing &&
    (activeDiagnosesOnTooth?.length === 0 ||
      (activeDiagnosesOnTooth?.length > 0 &&
        !activeToothLocations(payload.annotation.location)) ||
      (restorations.includes(payload.annotation.subtype!) &&
        !payload.isChecked))
  ) {
    yield put(setDrawingAction(DrawingAction[diagnose]))
  } else if (
    !drawing &&
    (activeDiagnosesOnTooth?.length === 2 ||
      (activeDiagnosesOnTooth?.length === 1 &&
        activeToothLocations(payload.annotation.location)) ||
      payload.isChecked ||
      excludedDrawingDetections.includes(diagnose))
  ) {
    yield put(setDrawingAction(DrawingAction.select))
  }

  if (enablingAnnotations) {
    // Toggling OFF -> ON
    // 1. Disable all annotations subtypes not allowed when enabling the event subtype annotations
    yield call(disableAnnotationCombinationsBySubtype, diagnose)

    // 2. Enable the event subtype annotations
    yield call(
      enableAnnotations,
      payload.annotation,
      changes,
      relevantDetections
    )
  } else {
    // Toggling ON -> OFF
    yield call(
      disableAnnotations,
      payload.annotation,
      changes,
      relevantDetections
    )
    // Only hide calculus when both mesial and distal is toggled off
    if (
      diagnose === AnnotationName.calculus &&
      filteredCalculusAdditions.length > 1
    ) {
      return
    }
    // Hide drawing canvas
    yield put(
      setDrawingCanvases([
        { activeId: `${toothName}-${diagnose}`, hidden: true },
      ])
    )
  }
}

function* togglePeriAnnotation({
  payload,
}: ReturnType<typeof adjustmentActions.togglePeriAnnotation>) {
  yield put(setDataIsChanged(true))
  if (payload.isChecked) {
    yield put(
      serverDataActions.addUserChanges([
        {
          action: "rejected",
          annotationId: payload.annotationId,
        },
      ])
    )
  } else {
    yield put(
      serverDataActions.deleteUserChange({
        id: payload.annotationId,
        deleteNonHSM: true,
      })
    )
  }
}

function* startExpandingSaga({
  payload: toothId,
}: ReturnType<typeof adjustmentActions.startExpanding>) {
  const teethAreShifting: boolean = yield select(
    adjSelectors.getTeethAreShifting
  )
  if (!teethAreShifting) return

  const bounds: ShiftingBounds | null = yield select(
    adjSelectors.getShiftingBounds
  )

  // Check if clicked tooth should expand/shrink our selection
  let newTeeth: number[] | false = false
  if (isUpper(toothId) === bounds?.upper) {
    const teeth: number[] = yield select(adjSelectors.getShiftingTeeth)

    const displayHorizontallyFlipped: boolean = yield select(
      serverDataSelectors.getDisplayHorizontallyFlipped
    )

    const offset = displayHorizontallyFlipped
      ? 15 - offsetFromLeft(toothId)
      : offsetFromLeft(toothId)

    const boundStart = getMovingIndexPosition(
      bounds.start,
      displayHorizontallyFlipped
    )

    if (offset === boundStart - 1) {
      newTeeth = [toothId, ...teeth]
    } else if (offset === boundStart + bounds.to) {
      newTeeth = [...teeth, toothId]
    } else if (offset === boundStart || offset === boundStart + bounds.to - 1) {
      newTeeth = teeth.filter((t) => t !== toothId)
    } else if (offset > boundStart && offset < boundStart + bounds.to) {
      return
    }
  }

  if (newTeeth) {
    yield put(adjustmentActions.expandShiftingToSuccess(newTeeth))
  } else {
    yield put(adjustmentActions.startExpandingSuccess(toothId))

    // if we had an active tooth, make the new tooth our activeTooth
    const activeTooth: number | null = yield select(
      serverDataSelectors.getActiveTooth
    )
    if (activeTooth) {
      yield put(teethActions.setActiveTooth(toothId))
    }
  }
}

function* expandShiftingToSaga({
  payload: { start, to },
}: ReturnType<typeof adjustmentActions.expandShiftingTo>) {
  const shiftingTeeth: number[] = yield select(adjSelectors.getShiftingTeeth)
  const upper = isUpper(shiftingTeeth[0])

  const { upLeft, upRight, downLeft, downRight } = yield select(
    teethSelectors.getTeethTemplate
  )
  const displayHorizontallyFlipped: boolean = yield select(
    serverDataSelectors.getDisplayHorizontallyFlipped
  )

  const teeth: number[] = upper
    ? upLeft.concat(upRight)
    : downLeft.concat(downRight)

  start = getMovingIndexPosition(start, displayHorizontallyFlipped)
  const nextStack = teeth.slice(start, start + to)
  yield put(adjustmentActions.expandShiftingToSuccess(nextStack))
}

function* setNextActiveStackSaga({
  payload: direction,
}: ReturnType<typeof adjustmentActions.setNextActiveStack>) {
  const teeth: number[] = yield select(adjSelectors.getShiftingTeeth)
  const nextToothMoveTo: (dir: number, cTooth: number) => number = yield select(
    serverDataSelectors.getNextToothMoveTo
  )
  const newTeeth = teeth.map((toothId: number) =>
    nextToothMoveTo(direction, toothId)
  )
  yield put(adjustmentActions.setNextActiveStackSuccess(newTeeth))
}

function* moveTeethSaga(direction: number) {
  let movingTeeth: number[] = yield select(adjSelectors.getShiftingTeeth)
  const teethToRender: Tooth[] = yield select(
    serverDataSelectors.getTeethToRender
  )

  const { start, to }: ShiftingBounds = yield select(
    adjSelectors.getShiftingBounds
  )

  const nextToothMoveTo: (dir: number, cTooth: number) => number = yield select(
    serverDataSelectors.getNextToothMoveTo
  )
  const endTooth = movingTeeth[direction > 0 ? movingTeeth.length - 1 : 0]
  const nextEdgeTooth = nextToothMoveTo(direction, endTooth)
  const detectedTooth = teethToRender.find(
    ({ toothName }) => toothName === nextEdgeTooth
  )
  if (detectedTooth) return false // Abort moving, there's a tooth in the way.
  yield put(withHistoryActions.rememberState())
  if (direction > 0) {
    // Moving right.
    movingTeeth = movingTeeth.reverse()
  }
  const allDetections: Detection[] = yield select(
    entitiesSelectors.getDetections
  )
  const allUserChanges: UserChange[] = yield select(
    serverDataSelectors.getAllUserChanges
  )
  let lastTooth = nextEdgeTooth // The tooth which is the target for the first to-be-moved-tooth.

  const allAdditions: AnnotationOnTooth[] = yield select(
    serverDataSelectors.getAllAdditions
  )

  const addedTeeth: Tooth[] = yield select(serverDataSelectors.getAllAddedTeeth)
  const removedTeeth: Tooth[] = yield select(
    serverDataSelectors.getAllRemovedTeeth
  )
  const detectedTeeth: Tooth[] = yield select(
    entitiesSelectors.getDetectedTeeth
  )
  const toothExists = (tooth: number) =>
    addedTeeth.some((candidate) => candidate.toothName === tooth) ||
    (detectedTeeth.some((candidate) => candidate.toothName === tooth) &&
      !removedTeeth.some((candidate) => candidate.toothName === tooth))

  yield all(
    // TODO (carlos): fix when multiple teeth move more than two times
    //    away and get back to original position, it leaves the same tooth name
    //    in added and deleted teeth.
    movingTeeth.map((tooth) => {
      const toothIsExisting = toothExists(tooth)
      const saveLastTooth = lastTooth
      lastTooth = tooth
      if (toothIsExisting) {
        return all([
          put(
            teethActions.deleteTooth({ id: tooth, rejectAnnotations: false })
          ),
          put(
            teethActions.createTooth({
              id: saveLastTooth,
              removeRejectedAnnotations: false,
            })
          ),
        ])
      } else {
        const saveLastToothIsExisting = toothExists(saveLastTooth)

        if (saveLastToothIsExisting) {
          return put(
            teethActions.deleteTooth({
              id: saveLastTooth,
              rejectAnnotations: false,
            })
          )
        } else {
          return null
        }
      }
    })
  )

  lastTooth = nextEdgeTooth // Reset previous tooth.

  // Change all detections (already moved and detected) to new tooth.
  yield all(
    movingTeeth.map((tooth) => {
      const movedDetectionIds = allUserChanges // All moved changes where this tooth is the target.
        .filter(
          (change) => change.action === "moved" && change.newTooth === tooth
        )
        .map((change) => change.annotationId)
      const movedAwayDetectionIds = allUserChanges // All moved changes which are not moved to this tooth.
        .filter(
          (change) => change.action === "moved" && change.newTooth !== tooth
        )
        .map((change) => change.annotationId)

      const detectionsForTooth = allDetections.filter(
        // All detections for this tooth.
        (detection) =>
          (detection.toothName === tooth ||
            movedDetectionIds.includes(detection.id)) &&
          !movedAwayDetectionIds.includes(detection.id)
      )

      const actions = detectionsForTooth.map((detection) => {
        if (detection.toothName === lastTooth) {
          // Tooth would be "moved" to original detection position. Do not add new userChange.
          return null
        }

        return put(
          serverDataActions.addUserChanges([
            // Add new moved annotation to the tooth before this map iteration.
            {
              annotationId: detection.id,
              action: "moved",
              newTooth: lastTooth,
            },
          ])
        )
      })

      lastTooth = tooth

      return all([
        put(serverDataActions.deleteMovedUserChange(tooth)), // First, delete old movedUserChange and then add the new ones.
        ...actions,
      ])
    })
  )

  // Move additions to new tooth.
  lastTooth = nextEdgeTooth
  yield all(
    movingTeeth.flatMap((tooth) => {
      const additionsForTooth = allAdditions.filter(
        (addition) => addition.toothName === tooth
      )
      const actions = additionsForTooth.map((addition) =>
        all([
          put(serverDataActions.deleteUserAddition(addition)),
          put(
            serverDataActions.addUserAdditions([
              { ...addition, toothName: lastTooth },
            ])
          ),
        ])
      )
      lastTooth = tooth

      return [...actions]
    })
  )

  // Move comments
  const movedTeeth = movingTeeth.map((tooth: number) =>
    nextToothMoveTo(direction, tooth)
  )
  yield put(
    serverDataActions.moveComments({
      oldTeeth: movingTeeth,
      movedTeeth,
    })
  )

  // store the mapping of old tooth number to new tooth number
  const oldMovedTeeth: Record<string, number> = yield select(
    serverDataSelectors.getMovedTeeth
  )
  const newMovedTeeth: Record<string, number> = { ...oldMovedTeeth }

  const reverseLookup: Record<string, number> = {}
  Object.entries(oldMovedTeeth).forEach(([k, v]) => {
    reverseLookup[v] = +k
    if (movedTeeth.includes(v)) {
      // overwrite all existing values for the range we're moving to
      delete newMovedTeeth[k]
    }
  })

  movingTeeth.forEach((tooth) => {
    const newKey = reverseLookup[tooth] ?? tooth
    const newValue = nextToothMoveTo(direction, tooth)
    if (newValue !== +newKey) {
      newMovedTeeth[newKey] = newValue
    } else {
      delete newMovedTeeth[newKey]
    }
  })

  yield put(serverDataActions.setMovedTeeth(newMovedTeeth))

  // move boneloss user changes
  const boneLossForm: BoneLossFormUser = yield select(
    serverDataSelectors.getBoneLossForm
  )
  const overwrittenTeeth = movedTeeth.filter((t) => !movingTeeth.includes(t))
  const changingTeeth = new Set(movingTeeth.concat(overwrittenTeeth))
  if (boneLossForm.changedTeeth.some((v) => changingTeeth.has(v.toothName))) {
    yield put(
      serverDataActions.saveBoneLossForm({
        ...boneLossForm,
        changedTeeth: boneLossForm.changedTeeth
          .filter((v) => !overwrittenTeeth.includes(v.toothName))
          .map((v) =>
            movingTeeth.includes(v.toothName)
              ? {
                  ...v,
                  toothName: nextToothMoveTo(direction, v.toothName),
                }
              : v
          ),
      })
    )
  }

  // Update UI to frame the moved selection.
  const displayHorizontallyFlipped: boolean = yield select(
    serverDataSelectors.getDisplayHorizontallyFlipped
  )
  yield put(
    adjustmentActions.expandShiftingTo({
      start: displayHorizontallyFlipped ? start - direction : start + direction,
      to,
    })
  )

  // if we have an active tooth in the range of moving teeth, we should update it
  const activeTooth: number | null = yield select(
    serverDataSelectors.getActiveTooth
  )
  if (activeTooth && movingTeeth.includes(activeTooth)) {
    const newActiveTeeth = nextToothMoveTo(direction, activeTooth)
    yield put(teethActions.setActiveTooth(newActiveTeeth))
  }
}

function* moveShiftingStackToSaga({
  payload: direction,
}: ReturnType<typeof adjustmentActions.moveShiftingStackTo>) {
  const movingTeeth: number[] = yield select(adjSelectors.getShiftingTeeth)
  const mappedAnnots: {} = yield select(
    entitiesSelectors.getEnabledMappedAnnotations
  )
  const nextToothMoveTo: (dir: number, cTooth: number) => number = yield select(
    serverDataSelectors.getNextToothMoveTo
  )
  const nextAnnots = movingTeeth.map((toothId: number) => ({
    [nextToothMoveTo(direction, toothId)]: {
      annotations: mappedAnnots[toothId],
    },
  }))
  const currentAnnots = movingTeeth.map((toothId: number) => ({
    [toothId]: undefined,
  }))
  const canMove: boolean = yield call(moveTeethSaga, direction)
  if (!canMove) return
  for (let loop = 0; loop <= nextAnnots.length - 1; loop++) {
    const nextTooth = nextAnnots[loop]
    const currTooth = currentAnnots[loop]
    yield put(adjustmentActions.adjustAnnotations(currTooth))
    yield put(adjustmentActions.adjustAnnotations(nextTooth))
  }
  yield put(adjustmentActions.toggleTeethAreShifting())
}

function* toggleTeethAreShiftingSaga() {
  // when the user starts shifting from the detail view
  // we want to leave the detail view and select the active tooth as the first movement
  const activeTooth: number | null = yield select(
    serverDataSelectors.getActiveTooth
  )
  const teethAreShifting: boolean = yield select(
    adjSelectors.getTeethAreShifting
  )
  let shiftingTeeth: number[] = []
  if (teethAreShifting && activeTooth) {
    const defaultActiveTooth: number | null = yield select(
      serverDataSelectors.getDefaultActiveTooth
    )
    if (!defaultActiveTooth) {
      // only set to activeTooth to null if we do not have a defaultActiveTooth
      yield put(teethActions.setActiveTooth(defaultActiveTooth))
    }
    shiftingTeeth = [activeTooth]
  } else if (!teethAreShifting) {
    const shiftingTeeth: number[] = yield select(adjSelectors.getShiftingTeeth)
    if (shiftingTeeth.length === 1) {
      yield put(teethActions.setActiveTooth(shiftingTeeth[0]))
    }
  }
  yield put(adjustmentActions.setNextActiveStackSuccess(shiftingTeeth))
}

export default function* adjustmentsSaga() {
  yield takeLatest(adjustmentTypes.ADJUST_ANNOTATIONS, adjustTeethAnnotsSaga)
  yield takeLatest(adjustmentTypes.MOVE_ANNOTATION, moveAnnotationStartSaga)
  yield takeLatest(adjustmentTypes.MOVE_ANNOTATION_TO, moveAnnotationToSaga)
  yield takeLatest(adjustmentTypes.START_EXPANDING, startExpandingSaga)
  yield takeLatest(adjustmentTypes.EXPAND_SHIFTING_TO, expandShiftingToSaga)
  yield takeEvery(
    adjustmentTypes.MOVE_SHIFTING_STACK_TO,
    moveShiftingStackToSaga
  )
  yield takeLatest(
    adjustmentTypes.SET_NEXT_ACTIVE_STACK,
    setNextActiveStackSaga
  )
  yield takeLatest(
    adjustmentTypes.TOGGLE_TEETH_ARE_SHIFTING,
    toggleTeethAreShiftingSaga
  )
  /** Toggling combinations of detection subtypes */
  // Main toggling: when clicking on main checkbox for each subtype
  yield takeEvery(
    adjustmentTypes.TOGGLE_ANNOTATION_ON_TOOTH,
    toggleAnnotationOnToothSaga
  )
  // For caries PRO:
  yield takeEvery(
    ServerDataTypes.ADD_CARIES_ADDITIONS,
    disableAnnotationCombinationsForCariesAdditions
  )
  yield takeEvery(
    ServerDataTypes.TOGGLE_ANNOTATION,
    disableAnnotationCombinationsByAnnotationId
  )
  yield takeEvery(adjustmentTypes.TOGGLE_PERI_ANNOTATION, togglePeriAnnotation)
}
