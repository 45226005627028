import { takeEvery, put, select, all } from "redux-saga/effects"

import * as filtersActions from "library/common/actions/filters"
import * as filtersSelectors from "library/common/selectors/filters"
import { getAvailableFilters } from "../selectors/entities"
import localStorage from "library/utilities/localStorage"

import { filterTypes } from "library/common/types/filterTypes"
import { FiltersState, FilterStatus } from "../reducers/filtersReducer"
import { setActiveTooth } from "../actions/teeth"
import { getDrawingCanvases } from "../selectors/drawing"
import { DrawingCanvases } from "../types/drawing"
import { setDrawingCanvases } from "../actions/drawing"
import { REACT_APP_REMEMBER_HSM } from "library/utilities/constants"
import { getIsHighSenseModeActive } from "../selectors/filters"

function* toggleFilterStatusSaga({
  payload: field,
}: ReturnType<typeof filtersActions.toggleFilterStatus>) {
  const state: FiltersState = yield select(filtersSelectors.getFiltersState)
  const availableFilters: Set<FilterStatus> = yield select(getAvailableFilters)

  const newState = { ...state, [field]: !state[field] }
  const allDisabled = Array.from(availableFilters).every((s) => !newState[s])

  if (field === FilterStatus.IS_ENABLED) {
    // if we enable the main toggle and everyone is disabled: enable them all
    if (newState.isEnabled && allDisabled) {
      availableFilters.forEach((s) => (newState[s] = true))
    }
  } else {
    // when transitioning from disable all to enabled:
    // only enable the specific field we clicked on
    if (!newState.isEnabled) {
      availableFilters.forEach((s) => (newState[s] = s === field))
      newState.isEnabled = field !== FilterStatus.SHOW_DRAWING // Only set to true if we are not toggling drawing filter
    }
    // let the isEnabled button follow along
    else newState.isEnabled = !allDisabled
  }

  // Toggle annotations drawing
  if (field === FilterStatus.SHOW_DRAWING) {
    const drawingCanvases: DrawingCanvases[] = yield select(getDrawingCanvases)

    yield all(
      drawingCanvases.map((d) => {
        if (d.activeId !== "annotate") return
        return put(
          setDrawingCanvases([
            {
              activeId: d.activeId,
              hidden: !d.hidden,
            },
          ])
        )
      })
    )
  }

  yield put(filtersActions.setFiltersStatus(newState))
}

function* deactivateActiveTooth() {
  const isHsmModeActive: boolean = yield select(getIsHighSenseModeActive)
  if (isHsmModeActive) yield put(setActiveTooth(null))
}

function* setHsmLocalStorage() {
  if (!REACT_APP_REMEMBER_HSM) return
  const isHsmModeActive: boolean = yield select(getIsHighSenseModeActive)
  localStorage.setItem("HSM", isHsmModeActive)
}

export default function* filtersSaga() {
  yield takeEvery(filterTypes.TOGGLE_FILTER_STATUS, toggleFilterStatusSaga)
  yield takeEvery(filterTypes.TOGGLE_HSM, deactivateActiveTooth)

  if (REACT_APP_REMEMBER_HSM) {
    yield takeEvery(
      [filterTypes.TOGGLE_HSM, filterTypes.SET_HSM],
      setHsmLocalStorage
    )
  }
}
