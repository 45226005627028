import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/drawing"
import {
  DrawingAction,
  DrawingCanvases,
  DrawingLineThickness,
} from "../types/drawing"

type DrawingState = Readonly<{
  drawingAction: DrawingAction
  drawingModeActive: boolean
  showAnnotationDrawingButton: boolean
  drawingLineThickness: DrawingLineThickness
  drawingCanvases: DrawingCanvases[]
  activeDrawingCanvas: string
  drawing: boolean
  showDrawingWarning: boolean
  hasDrawnAnnotation: boolean
}>

const initialState: DrawingState = {
  drawingAction: DrawingAction.select,
  drawingModeActive: false,
  showAnnotationDrawingButton: false,
  drawingLineThickness: DrawingLineThickness.medium,
  drawingCanvases: [],
  activeDrawingCanvas: "",
  drawing: false,
  showDrawingWarning: false,
  hasDrawnAnnotation: false,
}

type DrawingActions = ActionType<typeof actions>

export default (state = initialState, action: DrawingActions): DrawingState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return { ...initialState }
    }
    case getType(actions.setDrawingAction):
      return { ...state, drawingAction: action.payload }
    case getType(actions.toggleActiveDrawingMode):
      return {
        ...state,
        drawingModeActive: !state.drawingModeActive,
        drawingAction: state.drawingModeActive
          ? DrawingAction.select
          : state.drawingAction,
      }
    case getType(actions.setShowAnnotationDrawingButton):
      return { ...state, showAnnotationDrawingButton: action.payload }
    case getType(actions.setDrawingLineThickness):
      return { ...state, drawingLineThickness: action.payload }
    case getType(actions.setActiveDrawingCanvas):
      return { ...state, activeDrawingCanvas: action.payload }
    case getType(actions.setDrawing):
      return { ...state, drawing: action.payload }
    case getType(actions.setShowDrawingWarning):
      return { ...state, showDrawingWarning: action.payload }
    case getType(actions.setDrawingCanvases):
      return {
        ...state,
        drawingCanvases: [
          ...state.drawingCanvases.filter(
            (d) =>
              !action.payload.some((canvas) => canvas.activeId === d.activeId)
          ),
          ...action.payload,
        ],
      }
    case getType(actions.setHasDrawnAnnotation):
      return { ...state, hasDrawnAnnotation: action.payload }

    default:
      return state
  }
}
