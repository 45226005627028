export enum DrawingTypes {
  SET_DRAWING_ACTION = "@@DRAWING/SET_DRAWING_ACTION",
  TOGGLE_ACTIVE_DRAWING_MODE = "@@DRAWING/TOGGLE_ACTIVE_DRAWING_MODE",
  SET_SHOW_ANNOTATION_DRAWING_BUTTON = "@@/SET_SHOW_ANNOTATION_DRAWING_BUTTON",
  SET_INITIAL_STATE = "@@/SET_INITIAL_STATE",
  SET_DRAWING_LINE_THICKNESS = "@@/SET_DRAWING_LINE_THICKNESS",
  SET_DRAWING_CANVASES = "@@/SET_DRAWING_CANVASES",
  SET_ACTIVE_DRAWING_CANVAS = "@@/SET_ACTIVE_DRAWING_CANVAS",
  SET_DRAWING = "@@/SET_DRAWING",
  DELETE_DRAWING_CANVAS = "@@/DELETE_DRAWING_CANVAS",
  SET_SHOW_DRAWING_WARNING = "@@/SET_SHOW_DRAWING_WARNING",
  SET_HAS_DRAWN_ANNOTATION = "@@/SET_HAS_DRAWN_ANNOTATION",
}

export enum DrawingAction {
  select = "select",
  annotate = "annotate",
  erase = "erase",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
}

export enum DrawingLineThickness {
  thin = 3,
  medium = 6,
  thick = 9,
}

export type DrawingCanvases = Readonly<{
  activeId: string
  hidden: boolean
}>
