import { Theme } from "library/common/actions/user"

export const DEFAULT_THEME =
  (process.env.REACT_APP_DEFAULT_THEME as Theme) || Theme.dentalxrai

export const WHATS_NEW_VERSION = DEFAULT_THEME == Theme.dentalxrai ? "3" : "2"

export const DEFAULT_COACH_MARKS = {
  cariesPro: 0,
  boneLossPro: 0,
  calculusXrayImage: 0,
  calculusToothMap: 0,
  calculusEditView: 0,
}

export const SUBTYPE_RGB = {
  crowns: {
    red: 110,
    green: 229,
    blue: 255,
  },
  bridges: {
    red: 18,
    green: 179,
    blue: 217,
  },
}

export const ANNOTATION_HEX_COLORS = {
  caries: "#FF2800",
  apical: "#FF8C00",
  calculus: "#FFEC00",
  bridges: "#6EE5FF",
  crowns: "#12B3D9",
  fillings: "#355EF2",
  annotate: "#FFFFFF",
}

export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID || "4260739410006"

export const FEATURE_NERVUS = !!process.env.REACT_APP_FEATURE_NERVUS
export const FEATURE_CALCULUS = !!process.env.REACT_APP_FEATURE_CALCULUS
export const FEATURE_BONE_LOSS = !!process.env.REACT_APP_FEATURE_BONE_LOSS
export const FEATURE_DRAWING_MODE = !!process.env.REACT_APP_FEATURE_DRAWING_MODE
export const SHOW_INACTIVE_PBL_BUTTON =
  !!process.env.REACT_APP_SHOW_INACTIVE_PBL_BUTTON
export const REACT_APP_REMEMBER_HSM = !!process.env.REACT_APP_REMEMBER_HSM
