import { ActionType, getType } from "typesafe-actions"
import localStorage from "library/utilities/localStorage"

import * as actions from "library/common/actions/filters"
import { REACT_APP_REMEMBER_HSM } from "library/utilities/constants"

export enum FilterStatus {
  IS_ENABLED = "isEnabled",
  SHOW_CARIES = "showCaries",
  SHOW_APICAL = "showApical",
  SHOW_OTHER = "showOther",
  SHOW_DRAWING = "showDrawing",
}

export type FiltersState = Readonly<{
  isEnabled: boolean
  showCaries: boolean
  showApical: boolean
  showOther: boolean
  showDrawing: boolean
  isHighSenseModeActive: boolean
}>

const initialState: FiltersState = {
  isEnabled: true,
  showCaries: true,
  showApical: true,
  showOther: true,
  showDrawing: true,
  isHighSenseModeActive:
    REACT_APP_REMEMBER_HSM && !!localStorage.getItem("HSM"),
}

type FiltersActions = ActionType<typeof actions>

export default (state = initialState, action: FiltersActions): FiltersState => {
  switch (action.type) {
    case getType(actions.setFiltersStatus):
      return { ...action.payload }

    case getType(actions.toggleHSM):
      return { ...state, isHighSenseModeActive: !state.isHighSenseModeActive }

    case getType(actions.setHSM):
      return { ...state, isHighSenseModeActive: action.payload }

    case getType(actions.setInitialState):
      return {
        ...initialState,
        isHighSenseModeActive: state.isHighSenseModeActive,
      }

    default:
      return state
  }
}
