import { createAction } from "typesafe-actions"

import {
  DrawingTypes,
  DrawingAction,
  DrawingLineThickness,
  DrawingCanvases,
} from "../types/drawing"

export const setInitialState = createAction(DrawingTypes.SET_INITIAL_STATE)()

export const setDrawingAction = createAction(
  DrawingTypes.SET_DRAWING_ACTION
)<DrawingAction>()

export const toggleActiveDrawingMode = createAction(
  DrawingTypes.TOGGLE_ACTIVE_DRAWING_MODE
)()

export const setShowAnnotationDrawingButton = createAction(
  DrawingTypes.SET_SHOW_ANNOTATION_DRAWING_BUTTON
)<boolean>()

export const setDrawing = createAction(DrawingTypes.SET_DRAWING)<boolean>()

export const setShowDrawingWarning = createAction(
  DrawingTypes.SET_SHOW_DRAWING_WARNING
)<boolean>()

export const setDrawingLineThickness = createAction(
  DrawingTypes.SET_DRAWING_LINE_THICKNESS
)<DrawingLineThickness>()

export const setDrawingCanvases = createAction(
  DrawingTypes.SET_DRAWING_CANVASES
)<DrawingCanvases[]>()

export const setActiveDrawingCanvas = createAction(
  DrawingTypes.SET_ACTIVE_DRAWING_CANVAS
)<string>()

export const setHasDrawnAnnotation = createAction(
  DrawingTypes.SET_HAS_DRAWN_ANNOTATION
)<boolean>()
