import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import differenceInDays from "date-fns/differenceInDays"

import * as entitiesSelector from "library/common/selectors/entities"
import { isHighSensitivityMode } from "library/common/selectors/utils/filterSensAnnotations"
import {
  getIsHighSenseModeActive,
  getActiveNames,
  getIsEnabled,
} from "library/common/selectors/filters"
import { getCariesPro, getBonelossPro } from "library/common/selectors/image"
import { parseHsmData } from "library/common/selectors/utils/hsm"
import { getTeethTemplate } from "library/common/selectors/teeth"
import { getBoneloss } from "library/common/selectors/entities"

import {
  BBoxPosition,
  Detection,
  UserChange,
} from "library/common/types/dataStructureTypes"
import {
  AnnotationRecord,
  AnnotationToRender,
  ITooth,
} from "library/common/types/serverDataTypes"
import {
  AnnotationName,
  CariesDepth,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"
import * as annotationsUtils from "library/common/types/utils/annotations"
import { flipTeeth, applyMovedTeeth } from "library/utilities/tooth"
import {
  getLicence,
  getShowBoneLossLite,
  getLicenceExpire,
  getCalculus,
} from "./user"
import { Licence } from "../types/userTypes"
import { boundingBox } from "pages/Dashboard/Frames/XRayImage/utils/mathUtils"

export interface IMatchedTooth {
  tooth: number
  isDetected: boolean
}

export type ImageLayerData = Readonly<{
  id: number
  name?: string
  imageUrl: string
  visible: boolean
  opacity: number | undefined
  addedSubtype?: string
  bbox?: BBoxPosition
}>

export type ActiveToothImageLayerData = Readonly<{
  id: number
  imageUrl: string
  x: number
  y: number
  width: number
  height: number
}>

export const getAllUserChanges = (state: RootState) =>
  state.serverData.present.changes
export const getAllAdditions = (state: RootState) =>
  state.serverData.present.additions
export const getAllAddedTeeth = (state: RootState) =>
  state.serverData.present.addedTeeth
export const getAllRemovedTeeth = (state: RootState) =>
  state.serverData.present.removedTeeth
export const getMovedTeeth = (state: RootState) =>
  state.serverData.present.movedTeeth

export const getActiveTooth = (state: RootState) => state.teeth.activeTooth

export const getGeneralComment = (state: RootState) =>
  state.serverData.present.generalComment
export const getAddedComments = (state: RootState) =>
  state.serverData.present.addedComments

export const getBoneLossForm = (state: RootState) =>
  state.serverData.present.forms.boneLoss

export const getToothBoneLoss = (state: RootState) =>
  state.serverData.present.forms.boneLoss.changedTeeth

export const getDisabledAnnotations = (state: RootState) =>
  state.serverData.present.changes
    .filter((change) => change.action === "rejected")
    .map((change) => change.annotationId)

// Image filters lives here to allow undo/redo of stack
export const getBrightness = (state: RootState) =>
  state.serverData.present.imageFilters.brightness
export const getContrast = (state: RootState) =>
  state.serverData.present.imageFilters.contrast
export const getSaturation = (state: RootState) =>
  state.serverData.present.imageFilters.saturation

// Selectors for imageMeta.
export const getImageMeta = (state: RootState) =>
  state.serverData.present.imageMeta
export const getImageRotation = (state: RootState) =>
  state.serverData.present.imageMeta.angleImageRotation
export const getPatientID = (state: RootState) =>
  state.serverData.present.imageMeta.patientID
export const getPatientName = (state: RootState) =>
  state.serverData.present.imageMeta.patientName
export const getDateOfBirth = (state: RootState) =>
  state.serverData.present.imageMeta.dateOfBirth
export const getImageDate = (state: RootState) =>
  state.serverData.present.imageMeta.imageDate
export const getAnalysisDate = (state: RootState) =>
  state.serverData.present.imageMeta.analysisDate
export const getFileName = (state: RootState) =>
  state.serverData.present.imageMeta.fileName
export const getKind = (state: RootState) =>
  state.serverData.present.imageMeta.kind
export const getIsImageHorizontallyFlipped = (state: RootState) =>
  state.serverData.present.imageMeta.isImageHorizontallyFlipped
export const getDisplayHorizontallyFlipped = (state: RootState) =>
  !!state.serverData.present.imageMeta.displayHorizontallyFlipped

export const getTeethToRender = createSelector(
  [entitiesSelector.getDetectedTeeth, getAllAddedTeeth, getAllRemovedTeeth],
  (detectedTeeth, addedTeeth, removedTeeth) => {
    const removedIds = removedTeeth.map((tooth) => tooth.toothName)
    const teethToRender = detectedTeeth.filter(
      (tooth) => !removedIds.includes(tooth.toothName)
    )

    return teethToRender.concat(addedTeeth)
  }
)

export const getDetectionsToRenderForAllTeeth = createSelector(
  [entitiesSelector.getDetections, getAllUserChanges, getIsHighSenseModeActive],
  (allDetections, allUserChanges, isHighSensitivityModeActive) => {
    const acceptedHSMIds = allUserChanges
      .filter((change) => change.action === "accepted" && change.isHSM)
      .map((change) => change.annotationId)

    const activeDetections: Detection[] = allDetections.filter(
      (detection) =>
        isHighSensitivityModeActive ||
        !isHighSensitivityMode(detection.subtype) ||
        acceptedHSMIds.includes(detection.id)
    )

    const movedChange = allUserChanges.filter(
      (change) => change.action === "moved"
    )
    movedChange.forEach((toMove) => {
      const found = activeDetections.findIndex(
        (candidate) => candidate.id === toMove.annotationId
      )
      if (found !== -1) {
        activeDetections[found] = {
          ...activeDetections[found],
          toothName: toMove.newTooth ?? -1,
        }
      }
    })

    return activeDetections
  }
)

/**
 * Returns detections mapped by `general` subtypes. General subtypes means that different caries subtypes
 * will be included in its parent subtype.
 * E.g.: "caries" is the general subtype, but "caries_HSM" is another variation,
 * which will be included as part of the "caries" annotations.
 */
export const getDetectionsForActiveToothBySubtype = createSelector(
  [entitiesSelector.getDetections, getActiveTooth],
  (detections, activeTooth) => {
    const toothDetections = detections.filter(
      (detection) => detection.toothName === activeTooth
    )
    // TODO (carlos): subtype list must be defined types (not hard-coded)
    const subtypes = [
      "caries",
      "apical",
      "bridges",
      "crowns",
      "fillings",
      "implants",
      "roots",
      "calculus",
      "nervus",
    ]
    const detectionsMappedBySubtype: Record<string, Detection[]> = {}
    subtypes.forEach(
      (sub) =>
        (detectionsMappedBySubtype[sub] = toothDetections.filter(
          (detection) =>
            // => check function `isHighSensitivityMode` for accepted hsm subtypes
            detection.subtype === sub ||
            detection.subtype === `${sub}_HSM` ||
            detection.subtype === `${sub}_F2` ||
            detection.subtype === `${sub}_F3`
        ))
    )
    return detectionsMappedBySubtype
  }
)

export const getActiveToothExists = createSelector(
  [
    getActiveTooth,
    entitiesSelector.getDetectedTeeth,
    getAllAddedTeeth,
    getAllRemovedTeeth,
  ],
  (activeTooth, detectedTeeth, addedTeeth, removedTeeth) => {
    const toothExists = (tooth: number) =>
      addedTeeth.some((candidate) => candidate.toothName === tooth) ||
      (detectedTeeth.some((candidate) => candidate.toothName === tooth) &&
        !removedTeeth.some((candidate) => candidate.toothName === tooth))

    // activeTooth can be null, otherwise return a boolean
    if (!activeTooth) return null
    else return toothExists(activeTooth)
  }
)

export const getAnnotationsToRenderForAllTeeth = createSelector(
  [
    getDetectionsToRenderForAllTeeth,
    getAllAdditions,
    getAllUserChanges,
    getCariesPro,
    getKind,
    entitiesSelector.getDetectionVisibility,
    getIsHighSenseModeActive,
  ],
  (
    allDetections,
    additions,
    allUserChanges,
    cariesPro,
    kind,
    visibleEntities,
    isHighSenseModeActive
  ) => {
    const hsmData = parseHsmData(
      allDetections,
      allUserChanges,
      kind,
      visibleEntities,
      cariesPro,
      isHighSenseModeActive
    )

    const changedAnnotations = new Map<number, UserChange>()
    allUserChanges
      .filter((change) => change.action === "changed")
      .forEach((change) => changedAnnotations.set(change.annotationId, change))

    const annotations: AnnotationToRender[] = allDetections.map(
      ({
        id,
        toothName,
        subtype,
        location,
        depth,
        center_mass_x,
        center_mass_y,
        replacing,
      }) => {
        const type = annotationsUtils.getAnnotationNameType(subtype)
        const isHSM = !!isHighSensitivityMode(subtype)
        const changed = changedAnnotations.get(id)

        return {
          id,
          toothName,
          type,
          location:
            cariesPro || type === "calculus" // hide location when not caries pro is not active or type is not calculus
              ? changed?.location ?? location
              : "",
          depth: cariesPro ? changed?.depth ?? depth : CariesDepth.unknown, // hide depth when caries pro is not active
          subtype: annotationsUtils.getRestorationSubtype(subtype),
          shouldMakeActive: !isHSM || hsmData.accepted.includes(id),
          isAddition: false,
          // HSM:
          isHSM,
          isAcceptedHSM: hsmData.accepted.includes(id),
          isRejectedHSM: hsmData.rejected.includes(id),
          isUnconfirmedHSM: hsmData.unconfirmed.includes(id),
          isEnlargementHSM: hsmData.enlargements.includes(id),
          isDisplayableHSM: hsmData.hsmIdsToDisplay.includes(id),
          center_mass_x,
          center_mass_y,
          replacing,
        }
      }
    )

    const allAnnotations: AnnotationToRender[] = annotations.concat(
      additions.map((annot) => ({
        ...annot,
        shouldMakeActive: true,
        isAddition: true,
        // HSM:
        isHSM: false,
        isAcceptedHSM: false,
        isRejectedHSM: false,
        isUnconfirmedHSM: false,
        isEnlargementHSM: false,
        isDisplayableHSM: false,
      }))
    )
    return allAnnotations
  }
)

const getAnnotationsToRenderForActiveTooth = createSelector(
  [getAnnotationsToRenderForAllTeeth, getActiveTooth, getCariesPro],
  (allAnnotations, activeTooth, cariesPro) => {
    const toothAnnotations = allAnnotations.filter(
      (a) => a.toothName === activeTooth
    )
    return cariesPro
      ? // when caries pro is enabled we don't display duplicated HSM annotations already existing
        toothAnnotations.filter(
          (a) => !a.isEnlargementHSM || a.isAcceptedHSM || a.isRejectedHSM
        )
      : toothAnnotations
  }
)

export const isHSMAvailable = createSelector(
  [entitiesSelector.getDetections],
  (detections) => {
    return (
      detections.some(
        (d) => d.subtype === "caries_HSM" || d.subtype === "apical_HSM"
      ) ||
      !detections.some((d) => d.subtype == "caries" || d.subtype == "apical")
    )
  }
)

/**
 convert number to letter (1 => A, 2 => B, ...)
 Returns only capital letters and when characters used up
 it concatenates its product such as X,Y,Z,AA,AB.
 Supports up to double letters only. Max is 702 => ZZ.
 */
const toLetter = (detectionNumber: number) => {
  if (detectionNumber < 26) {
    return String.fromCharCode(detectionNumber + 65)
  } else {
    const div = Math.floor(detectionNumber / 26)
    const mod = detectionNumber % 26
    return String.fromCharCode(64 + div) + String.fromCharCode(65 + mod)
  }
}

/** Sorts a list of objects based on the object's value of a key pass as an argument. */
const sortByKey = (objs: AnnotationToRender[], key: string) => {
  return objs.sort((a, b) => a[key] - b[key])
}

/**
 * Use this to know if the image ONLY (no toothmap) should be displayed horizontally flipped.
 *   This considers both flipping flags on the system. `isImageHorizontallyFlipped` and `displayHorizontallyFlipped`
 */
export const getDisplayImageFlipped = createSelector(
  [getIsImageHorizontallyFlipped, getDisplayHorizontallyFlipped],
  (isImageHorizontallyFlipped, displayHorizontallyFlipped) =>
    isImageHorizontallyFlipped != displayHorizontallyFlipped
)

const getTeethListUnfiltered = createSelector(
  [
    getTeethTemplate,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getAddedComments,
    getCariesPro,
    getKind,
    getDisplayImageFlipped,
    getCalculus,
  ],
  (
    template,
    teethToRender,
    allAnnotations,
    addedComments,
    cariesPro,
    kind,
    displayImageFlipped,
    calculus
  ): Record<string, ITooth[]> | any[] => {
    if (kind === "PERI") {
      // split predicted from user added annotations.
      const predicted = allAnnotations.filter((a) => !!a.center_mass_x)
      const added = allAnnotations.filter((a) => !a.center_mass_x)
      // sort and flip only the predicted as they are the only ones who have center_mass_x
      let sorted = sortByKey(predicted, "center_mass_x")
      if (displayImageFlipped) {
        sorted = sorted.reverse()
      }
      // put HSM annotations last
      // TODO: ensure consistent letters for HSM annotations after acceptance
      sorted = sorted.sort((a, b) => +a.isHSM - +b.isHSM)
      return sorted.concat(added).map((a, key) => ({
        ...a,
        toothName: toLetter(key), // Replace toothName with letter of detection
      }))
    }

    const activeTeeth = new Set(teethToRender?.map((t) => t.toothName))
    const commentedTeeth = new Set(addedComments.map((t) => t.toothName))

    return Object.keys(template).reduce(
      (side, key) => (
        (side[key] = template[key].map((templateTooth: number) => {
          const isDetected = activeTeeth.has(templateTooth)
          const hasComment = commentedTeeth.has(templateTooth)
          const detectionsForTooth = allAnnotations.filter(
            (annotation) => annotation.toothName === templateTooth
          )

          const filterAnnotationsByType = (type: string) => {
            const annotations = detectionsForTooth.filter((a) => a.type == type)
            return cariesPro
              ? // when caries pro is enabled we don't display duplicated HSM annotations already existing
                annotations.filter(
                  (a) =>
                    !a.isEnlargementHSM || a.isAcceptedHSM || a.isRejectedHSM
                )
              : annotations
          }

          return {
            tooth: templateTooth,
            isDetected,
            hasComment,
            annotations: {
              caries: filterAnnotationsByType(AnnotationName.caries),
              apical: filterAnnotationsByType(AnnotationName.apical),
              restorations: filterAnnotationsByType(
                AnnotationName.restorations
              ),
              calculus:
                calculus && filterAnnotationsByType(AnnotationName.calculus),
              nervus: filterAnnotationsByType(AnnotationName.nervus),
            },
          }
        })),
        side
      ),
      {}
    )
  }
)

const getTeethList = createSelector(
  [
    getTeethTemplate,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getDisabledAnnotations,
    getAddedComments,
    getCalculus,
  ],
  (
    template,
    teethToRender,
    allAnnotations,
    disabledAnnotations,
    addedComments,
    calculus
  ) => {
    // TODO (Tim): Rewrite RightTeethmap Component to use direct values instead of formatting.
    const filteredAnnotations = allAnnotations.filter(
      ({ id }) => !disabledAnnotations.includes(id ?? 0)
    )
    const activeTeeth = new Set(teethToRender?.map((t) => t.toothName))
    const commentedTeeth = new Set(addedComments.map((t) => t.toothName))
    const filteredTeethList: Record<string, ITooth[]> = {}
    Object.entries(template).forEach(([key, teeth]) => {
      filteredTeethList[key] = teeth.map((templateTooth: number): ITooth => {
        const isDetected = activeTeeth.has(templateTooth)
        const hasComment = commentedTeeth.has(templateTooth)
        const detectionsForTooth = filteredAnnotations.filter(
          (annotation) => annotation.toothName === templateTooth
        )

        return {
          tooth: templateTooth,
          isDetected,
          hasComment,
          annotations: {
            caries: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.caries
            ),
            apical: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.apical
            ),
            restorations: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.restorations
            ),
            calculus: detectionsForTooth.filter(
              (annotation) =>
                // only populate with calculus if user has permission
                calculus && annotation.type === AnnotationName.calculus
            ),
            nervus: detectionsForTooth.filter(
              (annotation) => annotation.type === AnnotationName.nervus
            ),
          },
        }
      })
    })

    return filteredTeethList
  }
)

export const getTeethListForJawTeethMap = createSelector(
  [getTeethList],
  (fullTeethList) => {
    const newToothList: Record<string, ITooth[]> = {}
    Object.entries(fullTeethList).forEach(([key, value]) => {
      newToothList[key] = value.map((entry): ITooth => {
        // Filter teeth with no annotation to prohibit rendering these.
        const { annotations, hasComment } = entry
        if (
          hasComment ||
          Object.values(annotations || {}).some((a) => a.length)
        ) {
          return entry
        } else {
          // TODO (Tim): JawTeethMap currently expects annotations to be null. Change jawteethmap to match the new data caries.length>0...
          return { ...entry, annotations: null }
        }
      })
    })
    return newToothList
  }
)

export const getTeethListForRightTeethMap = createSelector(
  [getTeethListUnfiltered, getKind],
  (teethList, kind) => {
    if (kind === "PERI") {
      return teethList
    }

    const result: Record<string, ITooth[]> = {}
    const keys = ["upLeft", "upRight", "downRight", "downLeft"]

    keys.forEach((key: string) => {
      result[key] = teethList[key]?.filter((entry: ITooth) => {
        // Filter teeth with no annotation to prohibit rendering these.
        const { annotations, hasComment } = entry
        return (
          hasComment || Object.values(annotations || {}).some((a) => a.length)
        )
      })
      // in the detection list we go right to left on the bottom teeth:
      if (key.startsWith("down")) result[key]?.reverse()
    })

    return result
  }
)

export const annotationsDataForTooth = createSelector(
  // TODO (Tim): rewrite component to use getAnnotationsToRenderForActiveTooth instead.
  [getAnnotationsToRenderForActiveTooth, getActiveTooth, getCalculus],
  (activeAnnotations, activeTooth, calculus) => {
    if (activeTooth === null) {
      return {
        tooth: -1,
        annotations: {
          caries: [],
          apical: [],
          restorations: [],
          calculus: [],
          nervus: [],
        },
      }
    }

    const annotations: AnnotationRecord = {}
    const annotationNames = [
      AnnotationName.caries,
      AnnotationName.apical,
      AnnotationName.calculus,
      AnnotationName.restorations,
      AnnotationName.nervus,
    ]
    annotationNames.forEach(
      (type) =>
        (annotations[type] = activeAnnotations.filter(
          (annotationOnTooth) =>
            annotationOnTooth.type === type &&
            (annotationOnTooth.type !== AnnotationName.calculus || calculus)
        ))
    )

    return {
      tooth: activeTooth,
      annotations,
    }
  }
)

export const filteredAnnotationsDataForTooth = createSelector(
  [annotationsDataForTooth, getDisabledAnnotations],
  ({ tooth, annotations }, disabledAnnotations) => {
    // the same as annotationsDataForTooth, but filtered to exclude rejections
    const filteredAnnotations: AnnotationRecord = {}
    Object.keys(annotations).forEach(
      (key) =>
        (filteredAnnotations[key] = annotations[key].filter(
          ({ id }: AnnotationToRender) => !disabledAnnotations.includes(id ?? 0)
        ))
    )

    return {
      tooth,
      annotations: filteredAnnotations,
    }
  }
)

export const getNextToothMoveTo = createSelector(
  [getTeethTemplate, getActiveTooth],
  ({ upLeft, upRight, downLeft, downRight }, activeToothId: number | null) =>
    (nextIndex: number, currentToothId?: number | null) => {
      const toothToCompare = currentToothId || activeToothId || -1
      const upperTeeth = upLeft.concat(upRight)
      const lowerTeeth = downLeft.concat(downRight)
      const toothLocation = upperTeeth.includes(toothToCompare)
        ? upperTeeth
        : lowerTeeth
      const toothIndex = toothLocation.findIndex(
        (toothId) => toothId === toothToCompare
      )

      return toothIndex === -1 ? false : toothLocation[toothIndex + nextIndex]
    }
)

export const getNextToothNoGaps = createSelector(
  [getTeethToRender, getNextToothMoveTo],
  (detectedTeeth, nextToothMoveTo) => (direction: number) => {
    let current: number | false | undefined
    const teeth = new Set(detectedTeeth?.map((t) => t.toothName))
    while (true) {
      current = nextToothMoveTo(direction, current || null)
      if (!current || teeth.has(current)) return current
    }
  }
)

export const getChangedBonelossValues = createSelector(
  [getBoneloss, getToothBoneLoss, getMovedTeeth, getIsImageHorizontallyFlipped],
  (boneloss, toothBoneLoss, movedTeeth, flipped) => {
    const boneLossValues = applyMovedTeeth(
      flipTeeth(boneloss?.annotations || [], flipped),
      movedTeeth
    )

    const changedTeethSet = new Set(toothBoneLoss?.map((i) => i.toothName))
    return boneLossValues
      .filter((i) => !changedTeethSet.has(i.toothName))
      .map((data) => {
        return { ...data, isChecked: data.d > 0 || data.m > 0 }
      })
      .concat(toothBoneLoss || [])
  }
)

export const getMaxBoneLossTooth = createSelector(
  [getChangedBonelossValues, getTeethToRender],
  (changedBonelossValues, teethToRender) => {
    const values = changedBonelossValues.filter((c) =>
      teethToRender.some((t) => t.toothName === c.toothName)
    )

    const maxBoneLossPercent = Math.max(
      ...values.map((boneLossPerTooth) =>
        Math.max(boneLossPerTooth.d, boneLossPerTooth.m)
      )
    )

    const toothName =
      values.find(
        (c) => c.d === maxBoneLossPercent || c.m === maxBoneLossPercent
      )?.toothName || null

    return {
      maxBoneLossPercent,
      toothName,
    }
  }
)

export const getDefaultActiveTooth = createSelector(
  [getBonelossPro, getShowBoneLossLite, getMaxBoneLossTooth],
  (boneLossPro, showBoneLossLite, maxBoneLossTooth) =>
    boneLossPro && showBoneLossLite ? maxBoneLossTooth.toothName : null
)

export const getBoneLossFormValues = createSelector(
  [
    getBoneLossForm,
    getTeethToRender,
    getAnnotationsToRenderForAllTeeth,
    getMaxBoneLossTooth,
  ],
  (boneLossForm, teethToRender, annotations, maxBoneLossTooth) => {
    const maxBoneLossPercent = maxBoneLossTooth.maxBoneLossPercent

    const boneLossIndexValue =
      boneLossForm.age &&
      maxBoneLossPercent &&
      maxBoneLossPercent / boneLossForm.age

    const boneLossIndex = (() => {
      if (!boneLossIndexValue || !boneLossForm.age) {
        return ""
      }
      switch (true) {
        case boneLossIndexValue < 0.25:
          return "<0.25"
        case boneLossIndexValue <= 1:
          return "0.25-1.0"
        default:
          return ">1.0"
      }
    })()

    const boneLossIndexDisplayValue =
      boneLossForm.boneLossIndexOverride || boneLossIndex

    const grade = (() => {
      switch (true) {
        case boneLossIndexDisplayValue === ">1.0" ||
          boneLossForm.diabetes === "II" ||
          boneLossForm.smoking === ">=10":
          return "C"
        case boneLossIndexDisplayValue === "0.25-1.0" ||
          boneLossForm.diabetes === "I" ||
          boneLossForm.smoking === "<10":
          return "B"
        case boneLossIndexDisplayValue === "<0.25" ||
          boneLossForm.diabetes === "-" ||
          boneLossForm.smoking === "-":
          return "A"
        default:
          return "-"
      }
    })()

    // Exclude implants and wisdom teeth
    const ignoredTeeth = annotations
      .filter((a) => a.subtype === "implants")
      .map((a) => a.toothName)
      .concat([18, 28, 38, 48])

    const existingTeeth = teethToRender.filter(
      (tooth) => !ignoredTeeth.includes(tooth.toothName)
    )

    // 28 possible non-wisdom teeth
    const numberOfLostTeeth = 28 - existingTeeth.length

    const toothLoss = (() => {
      if (boneLossForm.toothLoss !== "") {
        return boneLossForm.toothLoss
      }
      switch (true) {
        case numberOfLostTeeth >= 5:
          return ">=5"
        case numberOfLostTeeth > 0:
          return "<=4"
        default:
          return "-"
      }
    })()

    const maxBoneLossPercentCategory = (() => {
      if (!maxBoneLossPercent) return
      switch (true) {
        case maxBoneLossPercent < 15:
          return "<15"
        case maxBoneLossPercent <= 33:
          return "15-33"
        default:
          return ">33"
      }
    })()

    const bonelossCategory =
      boneLossForm.maxBoneLossPercentCategoryOverride ||
      maxBoneLossPercentCategory

    const stage = (() => {
      switch (true) {
        case toothLoss === ">=5" || boneLossForm.complications === "complex":
          return "IV"
        case bonelossCategory === ">33" ||
          toothLoss === "<=4" ||
          boneLossForm.complications === "ST>=6":
          return "III"
        case bonelossCategory === "15-33" ||
          boneLossForm.complications === "ST=5":
          return "II"
        case bonelossCategory === "<15" || toothLoss === "-":
          return "I"
      }
    })()

    const draft = !(
      toothLoss &&
      boneLossForm.distribution &&
      boneLossForm.age &&
      boneLossIndexDisplayValue &&
      boneLossForm.diabetes &&
      boneLossForm.smoking
    )

    return {
      ...boneLossForm,
      stage,
      toothLoss,
      maxBoneLossPercent,
      maxBoneLossPercentCategory,
      grade,
      boneLossIndex,
      draft,
    }
  }
)

/**
 * Use it to check whether the user has a valid hsm annotation considering the current state of cariesPro.
 *    as selector`getAnnotationsToRenderForAllTeeth` uses the cariesPro variable, the result of this selector
 *    will depend whether the user has cariesPro enabled or not. In both cases, it could return a different result
 *    depending on whats the current logic to determine a blob/mask enlargement, which currently is different when
 *    cariesPro is enabled or not.
 *
 * NOTE: For circular import issues, there is another selector for the same case
 *    with a slight difference. Check `hasHsmAnnotations` in serverData selectors.
 */
export const hasHsmAnnotations = createSelector(
  [getAnnotationsToRenderForAllTeeth, getAllRemovedTeeth],
  (allAnnotations, allRemovedTeeth) => {
    const removedTeeth = new Set(allRemovedTeeth?.map((a) => a.toothName))
    const types = [AnnotationName.apical, AnnotationName.caries]
    const excludedTeeth = allAnnotations.flatMap(
      (a) =>
        ((a.subtype === RestorationSubtype.bridges ||
          a.subtype === RestorationSubtype.fillings) &&
          a.toothName) ||
        []
    )

    return allAnnotations.some(
      (a) =>
        // any unconfirmed that doesn't enlarge the mask/blob
        ((a.isUnconfirmedHSM && !a.isEnlargementHSM) ||
          // Since unconfirmed annotations change if you accept or reject an hsn annotations
          // we need to consider if a valid unconfirmed was also accepted or rejected.
          a.isAcceptedHSM ||
          a.isRejectedHSM) &&
        types.includes(a.type) &&
        !removedTeeth.has(Number(a.toothName)) &&
        a.toothName &&
        !excludedTeeth.includes(a.toothName)
    )
  }
)

export const getInferenceStatus = (state: RootState) =>
  state.serverData.present.inferenceStatus

const getToothSegments = createSelector(
  [entitiesSelector.getEntities, getMovedTeeth],
  (entities, movedTeeth) => applyMovedTeeth(entities.segments, movedTeeth)
)

export const getActiveToothLayers = createSelector(
  [getActiveTooth, getActiveToothExists, getToothSegments],
  (activeTooth, activeToothExists, toothSegments) => {
    if (activeToothExists && activeTooth) {
      const activeToothAnnotation = toothSegments.find(
        (s) => s.toothName == activeTooth
      )
      if (activeToothAnnotation) {
        const { id, mask } = activeToothAnnotation
        return [
          {
            id: id,
            imageUrl: `data:image/png;base64,${mask}`,
            visible: true,
            opacity: 0.5,
            ...boundingBox(activeToothAnnotation),
          },
        ]
      }
    }
    return []
  }
)

export const getToothPicker = createSelector(
  [getToothSegments, getImageMeta],
  (toothSegments, meta) => {
    const { imageWidth: width, imageHeight: height } = meta
    const teeth =
      toothSegments.length && width && height
        ? toothSegments.map((segment) => {
            const canvas = document.createElement("canvas")
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext("2d")!
            const image = new Image()

            const loadImage = async (url: string) => {
              const response = await fetch(url)
              const blob: any = response.ok && (await response.blob())
              return createImageBitmap(blob)
            }

            loadImage(`data:image/png;base64,${segment.mask}`)
              .then((bitmap) => {
                ctx?.drawImage(bitmap, 0, 0)
                if (
                  (image.width && image.width != width) ||
                  (image.height && image.height != height)
                ) {
                  console.error("error loading mask", segment.toothName, {
                    imgW: image.width,
                    imgH: image.height,
                    width,
                    height,
                  })
                }
              })
              .catch((err) => console.error(err))

            return {
              ctx,
              toothName: segment.toothName,
            }
          })
        : []

    return (x: number, y: number) =>
      x >= 0 && x < width && y >= 0 && y < height
        ? teeth.find((tooth) => tooth.ctx.getImageData(x, y, 1, 1).data[3])
            ?.toothName
        : undefined
  }
)

const getBonelossLayer = createSelector(
  [getBoneloss, getBonelossPro, getIsEnabled],
  (boneloss, bonelossPro, isEnabled) => {
    if (!boneloss) return []
    return {
      id: -1,
      imageUrl: `data:image/png;base64,${boneloss.mask}`,
      visible: !!bonelossPro && isEnabled,
      opacity: undefined,
    }
  }
)

export const getImageLayers = createSelector(
  [
    entitiesSelector.getDetectionVisibility,
    getActiveNames,
    entitiesSelector.getDetections,
    getIsHighSenseModeActive,
    entitiesSelector.getAllUserChangesDuplicatedForEntities,
    getCariesPro,
    getKind,
    getBonelossPro,
    getBonelossLayer,
    getAllAdditions,
  ],
  (
    visibleEntities,
    activeNames,
    entities,
    isHighSenseModeActive,
    changes,
    cariesPro,
    kind,
    bonelossPro,
    bonelossLayer,
    additions
  ) => {
    const hsmData = parseHsmData(
      entities,
      changes,
      kind,
      visibleEntities,
      cariesPro,
      isHighSenseModeActive
    )

    const getOpacity = (name: string) => {
      if (isHighSenseModeActive) return 0.2
      return name === "caries" ? 0.75 : undefined
    }

    const subtypeIsCrownOrBridge = (subtype: string | undefined) =>
      subtype === RestorationSubtype.bridges ||
      subtype === RestorationSubtype.crowns

    // ToothName array of additions that is either bridges or crowns
    const additionToothNames = additions.map(
      (a) => subtypeIsCrownOrBridge(a.subtype) && a.toothName
    )

    const teethIdsWithBridges = visibleEntities
      .filter((v) => v.detection.subtype === RestorationSubtype.bridges)
      .map((tooth) => tooth.detection.toothName)

    // Check if detections contain additions which are bridges or crowns
    const showCrownOrBridge = visibleEntities.some((v) =>
      additionToothNames.includes(v.detection.toothName)
    )

    const shouldShowCrownOrBridge = (detection: Detection) =>
      showCrownOrBridge &&
      subtypeIsCrownOrBridge(detection.subtype) &&
      additionToothNames.includes(detection.toothName)

    const layers = visibleEntities.map(
      ({ detection, name, visible }): ImageLayerData => ({
        id: detection.id,
        imageUrl: `data:image/png;base64,${detection.mask}`,
        name,
        visible:
          (activeNames[name] &&
            visible &&
            !bonelossPro &&
            !hsmData.hsmIdsToDisplay.includes(detection.id) &&
            !hsmData.hiddenIds.includes(detection.id)) ||
          shouldShowCrownOrBridge(detection),
        opacity: getOpacity(name),
        addedSubtype: shouldShowCrownOrBridge(detection)
          ? detection.subtype
          : undefined,
        ...(visible &&
          !teethIdsWithBridges.includes(detection.toothName) &&
          hsmData.hsmIdsToDisplay.includes(detection.id) && {
            bbox: detection.bbox,
          }),
      })
    )

    return layers.concat(bonelossLayer)
  }
)

// A reusable approach for future coach marks with any diagnoses would be a selector that accepts argument
// https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
/** Whether there is a calculus annotation to be rendered in the toothmap. */
export const getTeethMapHasCalculus = createSelector(
  [getTeethListForJawTeethMap, getKind],
  (teethList, kind) =>
    kind === "BW" &&
    Object.values(teethList).some((list) =>
      list.some((e) => e.annotations?.calculus?.length)
    )
)

export const getShowTrialPeriodMarketingModal = createSelector(
  [getLicenceExpire, getLicence],
  (licenceExpire, licence) => {
    const getLicenseExpirationDays = () => {
      if (!licenceExpire) return 0

      const today = new Date()
      const trialEnd = new Date(licenceExpire)
      return differenceInDays(trialEnd, today)
    }
    return licence === Licence.invalid || getLicenseExpirationDays() < 0
  }
)
