import fetch from "library/utilities/fetch"

export interface ReanalyzeRequest {
  id: string
  kind?: string
  rotate?: number
}

export const requestImageAnalysis = (id: string) =>
  fetch.get(`/result/${id}/?client=frontend`)

export const requestReanalyze = (data: ReanalyzeRequest) =>
  fetch.post(`/reanalyze/`, data)

export const requestPdfReport = (id: string, lang: string, theme: string) =>
  fetch.get(
    `/report-pdf/${id}.json?lang=${lang}&theme=${theme}&topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm`
  )

export const requestBoneLossPdfReport = (
  id: string,
  lang: string,
  theme: string
) =>
  fetch.get(
    `/report-pdf/${id}.json?lang=${lang}&theme=${theme}&topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&reports=bone-loss&boneLossDetections=0.5`
  )
