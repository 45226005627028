import { call, put, select, takeLatest } from "redux-saga/effects"

import localStorage from "library/utilities/localStorage"
import {
  ActionLogin,
  ActionResetPasswordFirstTime,
  loginActionSuccess,
  loginAction,
  loginActionError,
  newPasswordErrorAction,
  oldPasswordErrorAction,
  isLoadingResetPasswordAction,
  isSuccessResetPassword,
  setMustResetPassword,
  setUserInfo,
  setServerError,
  setHandlerHash,
  CoachMarkRecord,
} from "library/common/actions/user"
import { UserTypes } from "library/common/types/userTypes"
import { uploadActionNewImage } from "library/common/actions/upload"
import { setBearerToken } from "library/utilities/token"
import {
  requestLogin,
  requestGetUserName,
  requestSetKnownHandlers,
  requestSetWhatsNew,
  requestSetLastCoachMarks,
} from "library/services/userApi"
import {
  requestResetPassword,
  requestOnboarding,
} from "library/services/resetPasswordApi"
import { history } from "core/store/configureStore"
import { sha256 } from "library/utilities/hash"
import {
  getHandlerHash,
  getKnownHandlers,
  getLastCoachMarks,
} from "../selectors/user"
import { WHATS_NEW_VERSION } from "library/utilities/constants"
import { ensureActiveToothExists } from "./imageSaga"
import axios from "axios"

interface ILoginUser {
  payload: ActionLogin
  type: string
}

function* loginUser(userData: ILoginUser) {
  try {
    const authResponse: {
      data: {
        mustResetPassword: boolean
        token: string
        fullName: string
        email: string
        SSO: boolean
      }
    } = yield call(requestLogin, userData.payload)

    if (userData.payload.isRemember) {
      localStorage.setItem("email", userData.payload.email)
    }
    if (userData.payload.isRemember && !authResponse.data.mustResetPassword) {
      localStorage.setItem("access_token", authResponse.data.token)
    }

    setBearerToken(authResponse.data.token)
    yield put(uploadActionNewImage())
    yield put(loginActionSuccess(userData.payload))

    if (authResponse.data.mustResetPassword) {
      const { fullName, email, SSO } = authResponse.data
      yield put(setUserInfo({ fullName, email, SSO }))

      yield put(setMustResetPassword(true))
      yield put(loginAction())
      history.push("/onboarding", { token: authResponse.data.token })
    } else {
      yield put(loginAction())
    }
  } catch (error) {
    let errorCode = 600
    if (axios.isAxiosError(error)) {
      errorCode = error.response?.status === 400 ? 400 : 500
    }
    yield put(loginActionError(errorCode))
  }
}

interface IResetPassword {
  payload: {
    oldPassword?: string
    newPassword: string
    fullName?: string
    email?: string
    telephoneNumber?: string
    isFirstTime: boolean
  }
  type: string
}

interface IResetPasswordFirstTime {
  payload: ActionResetPasswordFirstTime
  type: string
}

function* resetPasswordFirstTime(data: IResetPasswordFirstTime) {
  const { token, ...resetPasswordPayload } = data.payload
  setBearerToken(token)
  const payload = {
    ...resetPasswordPayload,
    isFirstTime: true,
  }

  yield resetPassword({ payload, type: UserTypes.RESET_PASSWORD_ACTION })
}

function* resetPassword(password: IResetPassword) {
  const token = localStorage.getItem("access_token")
  if (token) setBearerToken(token)

  try {
    yield put(oldPasswordErrorAction(""))
    yield put(newPasswordErrorAction(""))
    yield put(isSuccessResetPassword(false))
    yield put(isLoadingResetPasswordAction(true))

    const { isFirstTime, ...data } = password.payload
    const passwordResponse: { data: { token: string } } = yield call(
      isFirstTime ? requestOnboarding : requestResetPassword,
      data
    )
    if (passwordResponse.data.token) {
      localStorage.setItem("access_token", passwordResponse.data.token)
      setBearerToken(passwordResponse.data.token)
    }
    if (isFirstTime) {
      yield put(setMustResetPassword(false))
    }
    yield put(isLoadingResetPasswordAction(false))
    yield put(isSuccessResetPassword(true))
  } catch (error) {
    yield put(isSuccessResetPassword(false))
    yield put(isLoadingResetPasswordAction(false))

    const errorData = error.response && error.response.data

    if (
      axios.isAxiosError(error) &&
      errorData &&
      errorData.newPassword &&
      typeof errorData.newPassword !== "string"
    ) {
      const commonPasswordError = errorData.newPassword.filter(
        (item: string) => item.indexOf("This password is too common.") !== -1
      )
      if (commonPasswordError) {
        yield put(newPasswordErrorAction("app.onboarding.common_password"))
      }
    } else if (typeof errorData.newPassword === "string") {
      const samePasswordError =
        errorData.newPassword === "Should be different from old password" ||
        errorData.newPassword ===
          "newPassword must be different from previous password"
      if (samePasswordError) {
        yield put(newPasswordErrorAction("app.onboarding.different_password"))
      }
    }
    if (errorData && errorData.oldPassword) {
      const isOldErrorPassword =
        errorData.oldPassword.indexOf("Wrong old password") !== -1
      if (isOldErrorPassword) {
        yield put(oldPasswordErrorAction("app.onboarding.old_password_error"))
      }
    }

    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status &&
      (!errorData || (!errorData.newPassword && !errorData.oldPassword))
    ) {
      yield put(setServerError("unavailable"))
    } else if (
      !navigator.onLine ||
      (axios.isAxiosError(error) && !error.response)
    ) {
      yield put(setServerError("disconnect"))
    }
  }
}

function* getUserName() {
  try {
    const { data } = yield call(requestGetUserName)
    const {
      fullName,
      email,
      SSO,
      knownHandlers,
      cariesPro,
      bonelossPro,
      boneLossLite,
      uploadsRemaining,
      whatsNew,
      lastCoachMarks,
      theme,
      calculus,
      nervus,
      licence,
      licenceExpire,
      showDrawingMode,
      boneLossOnly,
    } = data
    yield put(
      setUserInfo({
        fullName,
        email,
        SSO,
        knownHandlers,
        cariesPro,
        bonelossPro,
        boneLossLite,
        uploadsRemaining,
        whatsNew,
        lastCoachMarks,
        theme,
        calculus,
        nervus,
        licence,
        licenceExpire,
        showDrawingMode,
        boneLossOnly,
      })
    )
  } catch (error) {
    // console.error(error)
  }
}

function* submitHandlerHash() {
  try {
    const hash: string = yield select(getHandlerHash)
    const handlers: string[] = yield select(getKnownHandlers) ?? []
    const { data } = yield call(requestSetKnownHandlers, {
      knownHandlers: handlers.concat([hash]),
    })
    const { fullName, email, SSO, knownHandlers } = data
    yield put(setUserInfo({ fullName, email, SSO, knownHandlers }))
  } catch (error) {
    console.log(error)
  }
}

function* computeHandlerHash({ payload }: { payload: string; type: string }) {
  const handlerHash: string = yield call(sha256, payload)
  yield put(setHandlerHash(handlerHash))
}

export function* setWhatsNew() {
  try {
    yield call(requestSetWhatsNew, { whatsNew: WHATS_NEW_VERSION })
  } catch (error) {
    console.log("could not set whatsNew version", error)
  }
}

export function* setLastCoachMarks() {
  const lastCoachMarks: CoachMarkRecord = yield select(getLastCoachMarks) ?? {}
  try {
    yield call(requestSetLastCoachMarks, {
      lastCoachMarks,
    })
  } catch (error) {
    console.error("could not set lastCoachMark version", error)
  }
}

export default function* watchLogin() {
  yield takeLatest(UserTypes.LOGIN_ACTION_WITH_DATA, loginUser)
  yield takeLatest(UserTypes.RESET_PASSWORD_ACTION, resetPassword)
  yield takeLatest(
    UserTypes.RESET_PASSWORD_FIRST_TIME_ACTION,
    resetPasswordFirstTime
  )
  yield takeLatest(UserTypes.GET_USER_NAME, getUserName)
  yield takeLatest(UserTypes.SET_HANDLER_NAME, computeHandlerHash)
  yield takeLatest(UserTypes.SUBMIT_HANDLER_HASH, submitHandlerHash)
  yield takeLatest(UserTypes.SET_WHATS_NEW, setWhatsNew)
  yield takeLatest(UserTypes.SET_LAST_COACH_MARKS, setLastCoachMarks)
  yield takeLatest(UserTypes.TOGGLE_BONELOSS_PRO, ensureActiveToothExists)
}
