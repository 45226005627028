import { IReportProblem } from "library/common/actions/upload"
import { UserChange } from "library/common/types/dataStructureTypes"
import { BoneLossFormFull } from "library/common/types/serverDataTypes"
import fetch from "../utilities/fetch"
import { Buffer } from "buffer"

export interface IRequestUploadImage {
  meta: {
    fileName: string
  }
  data: string
}

export interface IRequestSendReport {
  resultId: string
  meta?: {
    isImageHorizontallyFlipped?: boolean
    patientID?: string
    patientName?: string
    dateOfBirth?: string
    imageDate?: string
  }
  generalComment?: string
  changes?: UserChange[]
  additions?: Array<{ type: string; subtype?: string; toothName: number }>
  removedTeeth?: Array<{ toothName: number }>
  addedTeeth?: Array<{ toothName: number }>
  movedTeeth?: Record<string, number>
  addedComments?: Array<{ comment: string; toothName: number }>
  cariesPro?: boolean
  bonelossPro?: boolean
  forms?: { boneLoss: BoneLossFormFull }
}

export interface IImageManipulation {
  brightness: number
  contrast: number
  saturation: number
}
interface IRequestEvent {
  action: string
  resultId: string
  editorOpenMs?: number
  button?: string
  imageManipulation?: IImageManipulation
  usedFullscreen?: boolean
}

export const requestUploadImage = (data: IRequestUploadImage) =>
  fetch.post("/upload/", data)
export const requestGetResultData = (id: string) =>
  fetch.get(`/result/${id}/?client=frontend`)
export const requestSendReport = (data: IRequestSendReport) =>
  fetch.post("/changes/", data)
export const requestSendChanges = (data: IRequestSendReport) =>
  fetch.post("/changes/", data)

export const requestSendReportProblem = (problem: IReportProblem) =>
  fetch.post(`/service-ticket/`, problem)
export const requestSendEvent = (data: IRequestEvent) =>
  fetch.post("/event/", data)

export const requestReAnalyze = (
  data: { id: string; kind: string } | { id: string; rotate: number }
) => fetch.post(`/reanalyze/`, data)
export const requestGetImage = (id: string) =>
  fetch
    .get(`/image/${id}/`, { responseType: "arraybuffer" })
    .then(
      (response) =>
        `data:image/jpeg;base64,${Buffer.from(response.data, "binary").toString(
          "base64"
        )}`
    )
