import { put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { toggleAnnotationOnTooth } from "../actions/adjustments"

import {
  setDrawingCanvases,
  setActiveDrawingCanvas,
  setDrawing,
} from "../actions/drawing"
import {
  drawingCanvasId,
  getDrawingAction,
  getDrawingCanvases,
} from "../selectors/drawing"
import { getActiveToothExists } from "../selectors/serverData"
import { getActiveTooth } from "../selectors/teeth"
import { AnnotationName, RestorationSubtype } from "../types/adjustmentTypes"
import { DrawingAction, DrawingTypes } from "../types/drawing"
import TeethTypes from "../types/teethTypes"

function* setDrawingCanvasSaga() {
  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const drawingCanvases: string[] = yield select(getDrawingCanvases)
  const activeId: string = yield select(drawingCanvasId)

  if (drawingAction !== DrawingAction.erase) {
    yield put(setActiveDrawingCanvas(activeId))
  }

  if (
    drawingAction === DrawingAction.select ||
    drawingAction === DrawingAction.erase ||
    drawingCanvases.includes(activeId)
  ) {
    return
  }

  yield put(setDrawingCanvases([{ activeId, hidden: false }]))
}

function* setDrawingAnnotation({ payload }: ReturnType<typeof setDrawing>) {
  const activeToothExist: boolean | null = yield select(getActiveToothExists)
  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const toothName: number = yield select(getActiveTooth)
  const activeId: string = yield select(drawingCanvasId)

  if (activeToothExist && activeId !== "annotate") {
    yield put(
      setDrawingCanvases([
        {
          activeId,
          hidden: false,
        },
      ])
    )
  }

  if (!payload) return

  if (
    drawingAction === DrawingAction.annotate ||
    drawingAction === DrawingAction.erase ||
    drawingAction === DrawingAction.select
  ) {
    return
  }

  const annotations = ["apical", "calculus", "caries"]
  const restorations = ["bridges", "crowns", "fillings"]

  const annotationType = annotations.includes(drawingAction)
    ? drawingAction
    : AnnotationName.restorations
  const restorationType = restorations.includes(drawingAction) && drawingAction

  yield put(
    toggleAnnotationOnTooth({
      annotation: {
        toothName,
        type: AnnotationName[annotationType],
        ...(restorationType && {
          subtype: RestorationSubtype[restorationType],
        }),
      },
      isChecked: false,
    })
  )
}

export default function* drawingSaga() {
  yield takeEvery(
    [DrawingTypes.SET_DRAWING_ACTION, TeethTypes.SET_ACTIVE_TOOTH],
    setDrawingCanvasSaga
  )
  yield takeLatest(DrawingTypes.SET_DRAWING, setDrawingAnnotation)
}
