import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"

import { FEATURE_DRAWING_MODE } from "library/utilities/constants"
import { getActiveTooth } from "./teeth"
import { DrawingAction } from "../types/drawing"
import { AnnotationName, RestorationSubtype } from "../types/adjustmentTypes"
import { filteredAnnotationsDataForTooth } from "./serverData"

export const drawingActionState = (state: RootState) =>
  state.drawing.drawingAction
export const getDrawingLineThickness = (state: RootState) =>
  state.drawing.drawingLineThickness
export const getDrawingCanvases = (state: RootState) =>
  state.drawing.drawingCanvases
export const getActiveDrawingCanvas = (state: RootState) =>
  state.drawing.activeDrawingCanvas
export const isDrawing = (state: RootState) => state.drawing.drawing
export const getShowDrawingWarning = (state: RootState) =>
  state.drawing.showDrawingWarning
export const getHasDrawnAnnotation = (state: RootState) =>
  state.drawing.hasDrawnAnnotation

export const getDrawingAction = createSelector(
  [drawingActionState, getActiveTooth],
  (drawingActionState, activeTooth) =>
    !activeTooth &&
    drawingActionState !== DrawingAction.annotate &&
    drawingActionState !== DrawingAction.erase
      ? DrawingAction.select
      : drawingActionState
)

export const isDrawingModeActive = (state: RootState) =>
  FEATURE_DRAWING_MODE && state.drawing.drawingModeActive
export const getShowAnnotationDrawingButton = (state: RootState) =>
  FEATURE_DRAWING_MODE && state.drawing.showAnnotationDrawingButton

export const drawingActionHasMatchingAnnotation = createSelector(
  [getDrawingAction, filteredAnnotationsDataForTooth],
  (drawingAction, filteredAnnotations) => {
    const annotations = ["apical", "calculus", "caries"]
    const restorations = ["bridges", "crowns", "fillings"]
    const annotationType = annotations.includes(drawingAction)
      ? drawingAction
      : AnnotationName.restorations
    const restorationType =
      restorations.includes(drawingAction) && RestorationSubtype[drawingAction]

    return annotationType === AnnotationName.restorations &&
      filteredAnnotations.annotations.restorations
      ? filteredAnnotations.annotations.restorations.some(
          (a) => a.subtype === restorationType
        )
      : filteredAnnotations.annotations[annotationType].length >= 1
  }
)

export const drawingCanvasId = createSelector(
  [getDrawingAction, getActiveTooth],
  (drawingAction, toothName) =>
    drawingAction === DrawingAction.annotate
      ? "annotate"
      : `${toothName}-${drawingAction}`
)
